import { createSlice } from "@reduxjs/toolkit";

import {
  getAboutUsService,
  getAboutUsHomeService,
  getBlogsService,
  getPeopleService,
  getClientsService,
  getPracticeTopicService,
  getTestimonialService,
  getTestimonialPeopleService,
  getPeopleTypeService,
  getPracticeContentService,
  getkeyPracticeService,
  getOfficeInfoService,
  createJobService,
  createContactUsService,
  getSlidersService,
  //
  getPrivacyService,
  getTermsService,
  getCookieService,
} from "../services/general_service";
// ----------------------------------------------------------------------

const initialState = {
  tab: "0",
  cardId: "0",
  headerTitle: "",
  headerTitleAr: "",
  // about us
  abotUsList: [],
  abotUsXsList: [],
  abotUsHome: {},
  aboutUsNext: "",
  abouUsLastPage: null,
  aboutUsXsNext: "",
  abouUsXsLastPage: null,
  abotUsSmList: [],
  aboutUsSmNext: "",
  abouUsSmLastPage: null,
  // blogs
  blogsList: [],
  isLoadingBlogs: false,
  blogsNext: "",
  blogsLastPage: null,
  //
  blogsListSm: [],
  blogsNextSm: "",
  blogsLastPageSm: null,
  //
  blogsListXs: [],
  blogsNextXs: "",
  blogsLastPageXs: null,

  // people
  peopleList: [],
  isLoadingPeople: false,
  peopleTypeList: [],
  isLoadingPeopleType: false,
  peopleTypeNext: "",
  peopleTypeLastPage: null,
  // clients
  clientsList: [],
  isLoadingClients: false,

  // practice-areas
  practiceTopicList: [],
  isLoadingPracticeTopic: false,
  practiceTopicNext: "",
  practiceTopicLastPage: null,
  practiceContentList: [],
  isLoadingPracticeContent: false,
  practiceContentNext: "",
  practiceContentLastPage: null,
  practiceTitle: {},
  isLoadingPracticeTitle: false,

  //Testimonial
  testimonialObj: {},
  isLoadingTestimonial: false,
  testimonialPeopleList: [],
  isLoadingTestimonialPeople: false,

  // office Info
  officeInfo: {},
  isLoadingOffice: false,

  // jobs
  isLoadingJobs: false,

  // contact us
  isLoadingContactUs: false,

  // sliders home
  isLoadingSliders: false,
  slidersList: [],
  slidersNext: "",
  slidersLastPage: null,
  //
  isLoadingPrivacy: false,
  isLoadingTerms: false,
  isLoadingCookie: false,
  privacy: {},
  terms: {},
  cookie: {},
};

const slice = createSlice({
  name: "general",
  initialState,
  reducers: {
    setTab: (state, action) => {
      state.tab = action.payload;
    },

    setCardId: (state, action) => {
      state.cardId = action.payload;
    },
    setTitle: (state, action) => {
      state.headerTitle = action.payload;
    },
    setTitleAr: (state, action) => {
      state.headerTitleAr = action.payload;
    },
  },
  extraReducers: {
    // GET
    [getAboutUsService.fulfilled]: (state, action) => {
      state.isLoadingAboutUs = false;
      state.abotUsList = action.payload?.data?.data;
      state.abotUsXsList = action.payload?.data?.data;
      state.abotUsSmList = action.payload?.data?.data;
      state.aboutUsNext = action.payload.data.next;
      state.aboutUsXsNext = action.payload.data.next;
      state.aboutUsSmNext = action.payload.data.next;

      state.abouUsLastPage = action.payload.data.last_page;
      state.abouUsXsLastPage = action.payload.data.last_page;
      state.abouUsSmLastPage = action.payload.data.last_page;
    },
    [getAboutUsService.rejected]: (state, action) => {
      state.isLoadingAboutUs = false;
    },
    [getAboutUsService.pending]: (state) => {
      state.isLoadingAboutUs = true;
    },

    // GET in home page
    [getAboutUsHomeService.fulfilled]: (state, action) => {
      state.isLoadingAboutUs = false;
      state.abotUsHome = action.payload?.data?.data;
    },
    [getAboutUsHomeService.rejected]: (state, action) => {
      state.isLoadingAboutUs = false;
    },
    [getAboutUsHomeService.pending]: (state) => {
      state.isLoadingAboutUs = true;
    },

    // GET blogs
    [getBlogsService.fulfilled]: (state, action) => {
      state.isLoadingBlogs = false;
      state.blogsList = action.payload?.data?.data;
      state.blogsNext = action.payload.data.next;
      state.blogsLastPage = action.payload.data.last_page;
      // sm
      state.blogsListSm = action.payload?.data?.data;
      state.blogsNextSm = action.payload.data.next;
      state.blogsLastPageSm = action.payload.data.last_page;
      // xs
      state.blogsListXs = action.payload?.data?.data;
      state.blogsNextXs = action.payload.data.next;
      state.blogsLastPageXs = action.payload.data.last_page;
    },
    [getBlogsService.rejected]: (state, action) => {
      state.isLoadingBlogs = false;
    },
    [getBlogsService.pending]: (state) => {
      state.isLoadingBlogs = true;
    },

    // GET clients
    [getClientsService.fulfilled]: (state, action) => {
      state.isLoadingClients = false;
      state.clientsList = action.payload?.data?.data;
    },
    [getClientsService.rejected]: (state, action) => {
      state.isLoadingClients = false;
    },
    [getClientsService.pending]: (state) => {
      state.isLoadingClients = true;
    },

    //
    [getPracticeTopicService.fulfilled]: (state, action) => {
      state.isLoadingPracticeTopic = false;
      state.practiceTopicList = action.payload?.data?.data;
      state.practiceTopicNext = action.payload.data.next;
      state.practiceTopicLastPage = action.payload.data.last_page;
    },
    [getPracticeTopicService.rejected]: (state, action) => {
      state.isLoadingPracticeTopic = false;
    },
    [getPracticeTopicService.pending]: (state) => {
      state.isLoadingPracticeTopic = true;
    },

    // GET timonial
    [getTestimonialService.fulfilled]: (state, action) => {
      state.isLoadingTestimonial = false;
      state.testimonialObj = action.payload?.data?.data;
    },
    [getTestimonialService.rejected]: (state, action) => {
      state.isLoadingTestimonial = false;
    },
    [getTestimonialService.pending]: (state) => {
      state.isLoadingTestimonial = true;
    },

    // GET timonial people
    [getTestimonialPeopleService.fulfilled]: (state, action) => {
      state.isLoadingTestimonialPeople = false;
      state.testimonialPeopleList = action.payload?.data?.data;
    },
    [getTestimonialPeopleService.rejected]: (state, action) => {
      state.isLoadingTestimonialPeople = false;
    },
    [getTestimonialPeopleService.pending]: (state) => {
      state.isLoadingTestimonialPeople = true;
    },

    // GET people type
    [getPeopleTypeService.fulfilled]: (state, action) => {
      state.isLoadingPeopleType = false;
      state.peopleTypeList = action.payload?.data?.data;
      state.peopleTypeNext = action.payload.data.next;
      state.peopleTypeLastPage = action.payload.data.last_page;
    },

    [getPeopleTypeService.rejected]: (state, action) => {
      state.isLoadingPeopleType = false;
    },

    [getPeopleTypeService.pending]: (state) => {
      state.isLoadingPeopleType = true;
    },

    // GET people
    [getPeopleService.fulfilled]: (state, action) => {
      state.isLoadingPeople = false;
      state.peopleList = action.payload?.data?.data;
    },
    [getPeopleService.rejected]: (state, action) => {
      state.isLoadingPeople = false;
    },
    [getPeopleService.pending]: (state) => {
      state.isLoadingPeople = true;
    },

    // Practice Content
    [getPracticeContentService.fulfilled]: (state, action) => {
      state.isLoadingPracticeContent = false;
      state.practiceContentList = action.payload?.data?.data;
      state.practiceContentNext = action.payload.data.next;
      state.practiceContentLastPage = action.payload.data.last_page;
    },
    [getPracticeContentService.rejected]: (state, action) => {
      state.isLoadingPracticeContent = false;
    },
    [getPracticeContentService.pending]: (state) => {
      state.isLoadingPracticeContent = true;
    },

    // GET key Practice
    [getkeyPracticeService.fulfilled]: (state, action) => {
      state.isLoadingPracticeTitle = false;
      state.practiceTitle = action.payload?.data?.data;
    },
    [getkeyPracticeService.rejected]: (state, action) => {
      state.isLoadingPracticeTitle = false;
    },
    [getkeyPracticeService.pending]: (state) => {
      state.isLoadingPracticeTitle = true;
    },

    // GET people
    [getOfficeInfoService.fulfilled]: (state, action) => {
      state.isLoadingOffice = false;
      state.officeInfo = action.payload?.data?.data;
    },
    [getOfficeInfoService.rejected]: (state, action) => {
      state.isLoadingOffice = false;
    },
    [getOfficeInfoService.pending]: (state) => {
      state.isLoadingOffice = true;
    },

    // POST add new job
    [createJobService.fulfilled]: (state, action) => {
      state.isLoadingJobs = false;
    },
    [createJobService.rejected]: (state, action) => {
      state.isLoadingJobs = false;
    },
    [createJobService.pending]: (state) => {
      state.isLoadingJobs = true;
    },

    // POST add new contact us
    [createContactUsService.fulfilled]: (state, action) => {
      state.isLoadingContactUs = false;
    },
    [createContactUsService.rejected]: (state, action) => {
      state.isLoadingContactUs = false;
    },
    [createContactUsService.pending]: (state) => {
      state.isLoadingContactUs = true;
    },

    // GET sliders
    [getSlidersService.fulfilled]: (state, action) => {
      state.isLoadingSliders = false;
      state.slidersList = action.payload?.data?.data;
      state.slidersNext = action.payload.data.next;
      state.slidersLastPage = action.payload.data.last_page;
    },
    [getSlidersService.rejected]: (state, action) => {
      state.isLoadingSliders = false;
    },
    [getSlidersService.pending]: (state) => {
      state.isLoadingSliders = true;
    },

    // GET terms
    [getTermsService.fulfilled]: (state, action) => {
      state.isLoadingTerms = false;
      state.terms = action.payload?.data?.data;
    },
    [getTermsService.rejected]: (state, action) => {
      state.isLoadingTerms = false;
    },
    [getTermsService.pending]: (state) => {
      state.isLoadingTerms = true;
    },

    // GET cookie
    [getCookieService.fulfilled]: (state, action) => {
      state.isLoadingCookie = false;
      state.cookie = action.payload?.data?.data;
    },
    [getCookieService.rejected]: (state, action) => {
      state.isLoadingCookie = false;
    },
    [getCookieService.pending]: (state) => {
      state.isLoadingCookie = true;
    },

    // GET privacy
    [getPrivacyService.fulfilled]: (state, action) => {
      state.isLoadingPrivacy = false;
      state.privacy = action.payload?.data?.data;
    },
    [getPrivacyService.rejected]: (state, action) => {
      state.isLoadingPrivacy = false;
    },
    [getPrivacyService.pending]: (state) => {
      state.isLoadingPrivacy = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setTab, setCardId, setTitle, setTitleAr } = slice.actions;
