import React, {useCallback, useEffect, useState} from "react";

import { Box, Typography } from "@mui/material";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import FormProvider, { RHFTextField } from "../../components/hook-form";

import { LoadingButton } from "@mui/lab";

import {allLangs, useLocales} from "../../locales";

import { useTheme } from "@mui/material/styles";

import { successToast } from "../../utils/toastMessage";

import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";

import { createJobService } from "../../redux/services/general_service";

import FooterSection from "../footer/FooterSection";

//--- seo ---
import {setMetaData} from "../../redux/slices/seoSlice";
import {careersMetaData} from "../../config/metaData";
import {getCurrentLanguage} from "../../utils/helper";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  //justifyContent: "flex-end",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pl: "30px",
  pr: "30px",
  mb: "50px",
  mt: { lg: "163px", xs: "150px", md: "160px", sm: "145px" },
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "54px", sm: "54px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  // textAlign: "center",
};

const lineStyle = {
  width: "66px",
  zIndex: 10,
  display: "flex",
  //justifyContent: "flex-end",
  mb: "30px",
};

const blurTitleStyle = {
  fontSize: { lg: "32px", xs: "32px" },
  fontWeight: 700,
  color: "#1E184C",
  mb: "15px",
  zIndex: 10,
  //textAlign: "center",
};

const contentColunmBox = {
  display: "flex",
  flexDirection: "column",
  //alignItems: "end",
  pl: { lg: "100px", md: "100px", sm: "30px", xs: "20px" },
  pr: { lg: "100px", md: "100px", sm: "30px", xs: "20px" },
};

const lableStyle = {
  fontSize: { lg: "20px", xs: "16px" },
  fontWeight: 700,
  color: "#000000",
  mb: "10px",
  zIndex: 10,
  // textAlign: "center",
};

const txtFieldBorderStyle = {
  borderRadius: "0px",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
  },
  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    borderRadius: "0px",
    height: "52px",
    border: "none",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const multiTxtFieldBorderStyle = {
  borderRadius: "0px",
  "& .MuiFormLabel-root": {
    color: "#BCBCBC",
    fontSize: "16px",
  },
  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    borderRadius: "0px",
    border: "none",
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
};

const topSaveButtonStyle = {
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#1E184C",
  bgcolor: "#61CCF5",
  borderRadius: "0px",

  width: { lg: "170px", sm: "142px", xs: "142px" },
  margin: { xs: "auto", sm: "0" },
  height: { lg: "48px", sm: "40px", xs: "40px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};
//___________________________________________________________
export default function CareersTab() {
  const { translate } = useLocales();

  const { isLoadingJobs } = useSelector((state) => state.general);

  const dispatch = useDispatch();

  const [imageFiles, setImageFiles] = useState(null);

  const theme = useTheme();

  const FormSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const { handleSubmit, reset, setValue } = methods;

  const currentLanguage = getCurrentLanguage(allLangs);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(careersMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("full_name", data?.full_name);
    formData.append("email", data?.email);
    formData.append("about_applier", data?.about_applier);
    formData.append("address", data?.address);
    formData.append("phone_number", data?.phone_number);
    formData.append("educational_degree", data?.educational_degree);
    formData.append("cv", imageFiles);

    dispatch(createJobService(formData)).then((response) => {
      if (response?.payload?.status === 200) {
        successToast("تمت عملية الإضافة بنجاح");
        reset(); //
        setValue("full_name", "");

        setValue("email", "");

        setValue("about_applier", "");

        setValue("address", "");

        setValue("phone_number", "");

        setValue("educational_degree", "");

        setValue("cv", null);

        setImageFiles(null); // Reset the state holding the file

        document.querySelector('input[type="file"][name="cv"]').value = ""; // Clear the file input in the DOM
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };
  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}></Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={bigWhiteTextStyle}>{translate("careers")}</Typography>
          <Box
            sx={{
              width: "66px",
              zIndex: 10,
              display: "flex",
            }}
          >
            <img alt="Line icon" src="/assets/icons/Line 1.svg" />
          </Box>
        </Box>
      </Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={contentColunmBox}>
          <Typography sx={blurTitleStyle}>{translate("job")}</Typography>
          <Box sx={lineStyle}>
            <img alt="Line icon" src="/assets/icons/Line 1.svg" />
          </Box>

          {/**Full Name */}
          <Typography sx={lableStyle}>{translate("name")}</Typography>
          <RHFTextField name="full_name" type="text" sx={txtFieldBorderStyle} />

          {/** Email */}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {translate("email")}
          </Typography>
          <RHFTextField name="email" type="email" sx={txtFieldBorderStyle} />

          {/** Address */}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {" "}
            {translate("address")}
          </Typography>
          <RHFTextField name="address" type="text" sx={txtFieldBorderStyle} />

          {/** Phone */}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {" "}
            {translate("phone")}
          </Typography>
          <RHFTextField
            name="phone_number"
            type="number"
            sx={txtFieldBorderStyle}
          />

          {/** Educational Degree */}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {translate("educational_degree")}
          </Typography>
          <RHFTextField
            name="educational_degree"
            type="text"
            sx={txtFieldBorderStyle}
          />

          {/** Applied Position */}
          {/**
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {translate("applied")}
          </Typography>
          <RHFTextField
            name="about_applier"
            type="text"
            sx={txtFieldBorderStyle}
          />
*/}
          {/** Write About Yourself */}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {translate("write_about")}
          </Typography>

          <RHFTextField
            multiline
            rows={4}
            name="about_applier"
            type="text"
            sx={multiTxtFieldBorderStyle}
          />

          {/** Resume/ CV Upload*/}
          <Typography sx={{ ...lableStyle, mt: "20px" }}>
            {translate("cv")}
          </Typography>
          <RHFTextField
            name="cv"
            type="file"
            onChange={(e) => setImageFiles(e.target.files[0])}
            sx={txtFieldBorderStyle}
          />

          <Box sx={{ mt: "20px" }}></Box>
          <LoadingButton
            fullWidth
            size="large"
            variant="Contained"
            sx={topSaveButtonStyle}
            type="submit"
            loading={isLoadingJobs}
          >
            {translate("submit")}
          </LoadingButton>

          <Box sx={{ mb: "50px" }}></Box>
        </Box>
      </FormProvider>

      <FooterSection />
    </>
  );
}
