import React from "react";

// mui
import { Box, Typography, Button, Grid, CircularProgress } from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { setTab } from "../../redux/slices/general";

import { useNavigate } from "react-router-dom";

//____________________________________________________

//---------- Styles -----------

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
  zIndex: 10,
};

const personStyle = {
  width: { lg: "477px", sm: "277px", xs: "auto", md: "449px" },
  height: { lg: "423px", sm: "314px", xs: "221px", md: "423px" },
  mb: { xs: "20px", sm: "0px" },
  margin: "auto",
};

const gridStyle = {
  pl: { lg: "30px", md: "30px", sm: "20px", xs: "30px" },
  pr: { lg: "30px", md: "30px", sm: "20px", xs: "30px" },
  pt: { sm: "15px" },
  //display: "flex",
  // alignItems: "center",
};

const flexBoxStyle = {
  display: "flex",
  // justifyContent: { lg: "flex-end", sm: "flex-end", xs: "center" },
  alignItems: "center",
  width: "100%",
};

const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "20px", sm: "15px", xs: "15px" },
  bgcolor: "rgba(97, 204, 245, 1)",
  height: { lg: "48px", sm: "40px", xs: "40px" },
  width: { lg: "170px", sm: "142px", xs: "142px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: { lg: "20px", xs: "0px", sm: "5px" },
  mb: { xs: "20px", sm: "0px" },
  mx: { xs: "auto", sm: 0 },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  lineHeight: "24px",
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "20px",
  mt: "50px",
};

const textStyle = {
  fontSize: { lg: "20px", sm: "16px", xs: "16px", md: "20px" },
  lineHeight: { lg: "32px", sm: "24px", xs: "28px", md: "32px" },
  fontWeight: 500,
  color: "#1E184C",
  mb: { xs: "15px", sm: "0px" },
  pr: { lg: "24px", sm: "0px" },
  textAlign: "justify",
};

//_______________________________________________________
export default function AboutUs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const theme = useTheme();

  const { abotUsHome, isLoadingAboutUs } = useSelector(
    (state) => state.general
  );

  return (
    <>
      <Box sx={boxTitleStyle}>
        <Typography sx={titleStyle}> {translate("about_us")}</Typography>
        <img alt="line icon" src="/assets/icons/Line 1.svg" />
      </Box>

      <Box sx={gridStyle}>
        <Grid container columnSpacing={{ lg: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            {isLoadingAboutUs ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="secondary" />
              </Box>
            ) : (
              <Box
                component="img"
                src={`${baseUrl}/${abotUsHome.image}`}
                sx={personStyle}
                alt={"ayyad al adwani image"}
              ></Box>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography sx={{ ...textStyle }}>
                {theme.direction === "ltr"
                  ? abotUsHome.details_en
                  : abotUsHome.details_ar}
                {}
              </Typography>

              <Box sx={flexBoxStyle}>
                <Button
                  fullWidth
                  disableRipple
                  size="large"
                  type="submit"
                  variant="Contained"
                  sx={buttonAiStyle}
                  onClick={(event) => {
                    navigate(`/about-us`);
                    dispatch(setTab("about-us"));
                  }}
                >
                  {translate("explore_more")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
