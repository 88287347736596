import React, { useState, useEffect, useCallback } from "react";

import { Box, Typography, Grid } from "@mui/material";

import PracticeAreasCard from "../../components/ui-component/PracticeAreasCard";

// reducer
import { setCardId, setTitle, setTitleAr } from "../../redux/slices/general";

import { useDispatch, useSelector } from "react-redux";

import {allLangs, useLocales} from "../../locales";

import { getPracticeTopicService } from "../../redux/services/general_service";

import InfiniteScroll from "react-infinite-scroll-component";

import { useNavigate } from "react-router-dom";

import FooterSection from "../footer/FooterSection";

//--- seo ---
import { setMetaData } from "../../redux/slices/seoSlice";
import { practiceAreaMetaData } from "../../config/metaData";
import {getCurrentLanguage} from "../../utils/helper";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  //justifyContent: "flex-end",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pl: "30px",
  pr: "30px",
  mb: "50px",
  mt: { lg: "163px", xs: "150px", md: "160px", sm: "145px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  // textAlign: "end",
  mb: "5px",
  mt: { lg: "20px" },
  //pr: { lg: "0px", xs: "40px", md: "0px", sm: "0px" },
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "54px", sm: "54px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  // textAlign: "center",
};

const gridStyle = {
  pl: { xs: "20px", lg: "80px", sm: "20px" },
  pr: { xs: "20px", lg: "80px", sm: "20px" },
};

//___________________________________________________________
export default function PracticeAreasTab() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { translate } = useLocales();

  // const theme = useTheme();

  const {
    practiceTopicList,
    practiceTopicNext,
    practiceTopicLastPage,
    isLoadingPracticeTopic,
  } = useSelector((state) => state.general);

  const [hasMore, setHasMore] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [aboutUs, setAboutUsList] = useState(practiceTopicList || []);

  useEffect(() => {
    if (practiceTopicList && practiceTopicList.length > 0) {
      setAboutUsList((prev) => [...prev, ...practiceTopicList]);

      setHasMore(practiceTopicNext && aboutUs.length < practiceTopicLastPage);
    }
  }, [practiceTopicList, practiceTopicNext, practiceTopicLastPage]);

  const handleLoadMoreClients = () => {
    if (hasMore && !isLoadingPracticeTopic) {
      const nextPage = currentPage + 1;

      setCurrentPage(nextPage);

      dispatch(getPracticeTopicService({ page: nextPage, page_size: 10 })).then(
        (res) => {
          if (res.payload.data.data.length === 0) {
            setHasMore(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    setAboutUsList([]);

    setCurrentPage(1);

    setHasMore(true);
    dispatch(
      getPracticeTopicService({
        page: 1,
        page_size: 10,
      })
    );
  }, []);

  const currentLanguage = getCurrentLanguage(allLangs);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(practiceAreaMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}></Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={bigWhiteTextStyle}>
            {translate("key_practice_areas")}
          </Typography>
          <Box
            sx={{
              width: "66px",
              zIndex: 10,
              display: "flex",
              // justifyContent: "flex-end",
            }}
          >
            <img alt="line icon" src="/assets/icons/Line 1.svg" />
          </Box>
        </Box>
      </Box>

      <InfiniteScroll
        dataLength={aboutUs.length}
        next={handleLoadMoreClients}
        hasMore={hasMore}
        loader={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography mb="30px">loading ...</Typography>
          </Box>
        } // Use ClipLoader here
        endMessage={
          <p style={{ textAlign: "center", ...titleStyle }}>
            <b>{translate("you_have_seen_it_all")}</b>
          </p>
        }
      >
        <Grid container columnSpacing={1} mt="20px" pb="20px" sx={gridStyle}>
          {aboutUs.map((item) => (
            <Grid
              onClick={(event) => {
                // eslint-disable-next-line no-lone-blocks

                dispatch(setCardId(item.id));
                dispatch(setTitleAr(item.title_ar));
                dispatch(setTitle(item.title_en));

                navigate(`/${item.title_en}`);
              }}
              item
              xs={12}
              sm={6}
              lg={6}
              key={item.id}
            >
              <PracticeAreasCard item={item} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
      <FooterSection />
    </>
  );
}
