import React, { useEffect } from "react";

// mui
import { Box, Typography, Button } from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { getSlidersService } from "../../redux/services/general_service";

import { setTab } from "../../redux/slices/general";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper/modules";

import { useNavigate } from "react-router-dom";

import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

//____________________________________________________

//---------- Styles -----------

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "54px", sm: "40px", xs: "24px" },
  lineHeight: { lg: "64px", sm: "50px", xs: "38px" },
  fontWeight: 800,
  color: "rgba(255, 255, 255, 1)",
  mb: "15px",
  zIndex: 10,
};

const contentStyle = {
  fontSize: { lg: "20px", sm: "16px", xs: "16px" },
  lineHeight: { lg: "32px", sm: "28px", xs: "28px" },
  fontWeight: 500,
  color: "rgba(251, 251, 251, 1)",
  zIndex: 10,
  textAlign: "justify",
};

const flexBoxStyle = {
  display: "flex",
  // justifyContent: { lg: "flex-end", sm: "flex-end", xs: "center" },
  alignItems: "center",
  width: "100%",
};

const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "20px", sm: "15px", xs: "15px" },
  bgcolor: "rgba(97, 204, 245, 1)",
  height: { lg: "48px", sm: "40px", xs: "40px" },
  width: { lg: "170px", sm: "142px", xs: "142px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: "20px",
  mb: { xs: "20px", sm: "0px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

//_______________________________________________________
export default function Introduction() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const theme = useTheme();

  const { slidersList } = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(
      getSlidersService({
        page: 1,
        page_size: 3,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Swiper
        className="custom-swiper"
        modules={[Pagination]}
        pagination={{ clickable: true }}
        spaceBetween={30}
        slidesPerView={1}
      >
        {slidersList.map((item, index) => {
          const title =
            theme.direction === "ltr" ? item.title_en : item.title_ar;
          const words = title.split(" ");
          const firstWord = words[0] || "";
          const secondWord = words[1] || "";
          const thirdWord = words[2] || "";
          const remainingWords = words.slice(3).join(" ");

          return (
            <SwiperSlide key={index}>
              <Box
                sx={{
                  background: `url(${baseUrl}/${item.image})`,
                  width: "100%",
                  position: "relative",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "667px",
                  pb: { xs: "20px" },
                  pt: { xs: "20px" },
                  mt: { lg: "163px", xs: "155px", md: "163px", sm: "126px" },
                }}
              >
                <Box sx={overlayStyle}></Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: { md: "45%", xs: "80%", sm: "80%" },
                  }}
                >
                  <Typography sx={{ ...bigWhiteTextStyle }}>
                    {firstWord}{" "}
                    <Typography
                      component="span"
                      sx={{
                        ...bigWhiteTextStyle,
                        color: "rgba(97, 204, 245, 1)",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      {secondWord} {thirdWord}
                    </Typography>{" "}
                    {remainingWords}
                  </Typography>

                  <Typography sx={contentStyle}>
                    {theme.direction === "ltr"
                      ? item.details_en
                      : item.details_ar}
                  </Typography>

                  <Box sx={{ ...flexBoxStyle }}>
                    <Button
                      fullWidth
                      disableRipple
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={buttonAiStyle}
                      onClick={(event) => {
                        navigate(`/about-us`);

                        dispatch(setTab("about-us"));
                      }}
                    >
                      {translate("explore_more")}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}
