import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
} from "@mui/material";

// reducer
import { setCardId } from "../../redux/slices/general";

import { useDispatch } from "react-redux";

import { useTheme } from "@mui/material/styles";

//_________________________________________________________

//* ------- Styles --------

const blackTitle = {
  fontSize: { lg: "24px", md: "24px", sm: "20px", xs: "20px" },
  fontWeight: 600,
  color: "rgba(0, 0, 0, 1)",
  textAlign: "center",
};

const bigColumnFlexBox = {
  display: "flex",
  borderRadius: 0,
  margin: "auto",
  boxShadow: "3px 4px 14.6px -2px #00000026",
  flexDirection: "column",
  background: "rgba(255, 255, 255, 1)",
  pb: { xs: "66px", sm: "0px" },
  alignItems: "center",
  width: { lg: "530px", xs: "100%", sm: "100%" },
  border: "1px solid #ADADAD",
  mb: "40px",
};

const cardContentSx = {
  height: "139px",
  my: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
//___________________________________________________________
export default function PracticeAreasCard({ item }) {
  const dispatch = useDispatch();

  const theme = useTheme();
  const baseUrl = "https://backend.aladwani.sourcemediakw.com";
  //________________________________________

  return (
    <>
      <Card className="custom-card" sx={{ ...bigColumnFlexBox }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="313px"
            image={`${baseUrl}/${item.image}`}
            alt={"practice area"}
          />
          <CardContent sx={cardContentSx}>
            <Typography
              onClick={(event) => {
                dispatch(setCardId(item.id));
              }}
              sx={{ ...blackTitle }}
            >
              {theme.direction === "ltr" ? item.title_en : item.title_ar}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}
