import PropTypes from "prop-types";

import { useEffect } from "react";

import React, { useState } from "react";

import { useLocation } from "react-router-dom";

import { Box, Drawer, Tab, Tabs } from "@mui/material";

import { NAV } from "../../../config";

import useResponsive from "../../../hooks/useResponsive";

// Tabs
import HomeTab from "../../../sections/tabs/HomeTab";

import AboutUsTab from "../../../sections/tabs/AboutUsTab";

import CareersTab from "../../../sections/tabs/CareersTab";

import NewsInsightstab from "../../../sections/tabs/NewsInsightstab";

import OurPeopleTab from "../../../sections/tabs/OurPeopleTab";

import PracticeAreasTab from "../../../sections/tabs/PracticeAreasTab";

// reducer
import { setTab } from "../../../redux/slices/general";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { useLocales } from "../../../locales";

import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const TABS = [
  {
    value: "home",
    label: "home",
    component: <HomeTab />,
  },
  {
    value: "about-us",
    label: "about_us",
    component: <AboutUsTab />,
  },
  {
    value: "practice-areas",
    label: "practice_areas",
    component: <PracticeAreasTab />,
  },

  {
    value: "our-people",
    label: "our_people",
    component: <OurPeopleTab />,
  },
  {
    value: "blogs",
    label: "blogs",
    component: <NewsInsightstab />,
  },

  {
    value: "careers",
    label: "careers",
    component: <CareersTab />,
  },
];

const styleTabs = {
  display: "flex",
  flexDirection: "column",
  bgcolor: "rgba(255, 255, 255, 0.9)",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  //pt: "20px",

  "& button.Mui-selected": {
    color: "#697D95",
    fontSize: "16px",
    fontWeight: 550,
    lineHeight: "28px",
    minHeight: "40px",
  },

  "& .MuiTabs-flexContainer": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: "20px",
    // justifyContent: "center",
  },

  "& .MuiTabs-scroller": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //  justifyContent: "center",
  },

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "16px",
    fontWeight: 500,
    minHeight: "40px",
    lineHeight: "20px",
  },
};

const styleClickableTab = {
  borderRadius: "8px",
  minWidth: "134px",
};

const styleTab = {
  minHeight: "35px",
  minWidth: "134px",
};

//______________________________________________
NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const theme = useTheme();

  const { translate } = useLocales();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { tab } = useSelector((state) => state.general);

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const [currentTab, setCurrentTab] = useState(tab);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    dispatch(setTab(tab));
  }, [tab, tab]);

  const handleChange = async (event, newValue) => {
    setCurrentTab(newValue);

    await dispatch(setTab(newValue)); // Wait for the action to complete

    navigate(`/${newValue}`); // Navigate after the state has been updated
  };

  const renderContent = (
    <>
      <Tabs
        value={currentTab}
        // onChange={(event, newValue) => {
        //    setCurrentTab(newValue);
        //   dispatch(setTab(newValue));
        //   navigate(`${newValue}`);
        // }}
        onChange={handleChange}
        sx={styleTabs}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {TABS.map((tab) => (
          <Tab
            sx={
              tab.value === currentTab
                ? {
                    styleClickableTab,
                  }
                : { styleTab }
            }
            key={tab.value}
            value={tab.value}
            label={translate(tab.label)}
          />
        ))}
      </Tabs>
    </>
  );

  return (
    <>
      <Box component="nav">
        <Drawer
          //anchor={theme.direction === "ltr" ? "left" : "right"}
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </Box>
      <></>
    </>
  );
}
