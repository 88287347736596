export const aboutUsURL = "/about-us/";

export const aboutUsHomeURL = "/home/about-us/";

export const blogsURL = "/blogs/";

export const clientsURL = "/clients/";

export const practiceTopicURL = "/practice-areas/topic/";

export const testimonialURL = "/testimonial/";

export const testimonialPeopleURL = "/testimonial-people/";

export const getPeopleURL = "/people/";

export const getPeopleTypeURL = "/people-types/";

export const getkeyPracticeURL = "/key-practice-area/";

export const getOfficeInfoURL = "/office-info/";

export const createJobsURL = "/job-appliers/apply/";

export const getSlidersHomeURL = "/home/main-sliders/";

export const createContactUsURL = "/contact-us/create/";

export const getPrivacyURL = "/privacy-policy/";

export const getTermsURL = "/terms-conditions/";

export const getCookieURL = "/cookie-policy/";
