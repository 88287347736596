import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const SEOMetaTags = ({data}) => (
    <Helmet>
        {data.title && <title>{data.title}</title>}
        {data.description && <meta name="description" content={data.description}/>}
        {data.url && <link rel="canonical" href={data.url}/>}
        {data.keywords && <meta name="keywords" content={data.keywords}/>}
        {data.additionalMetaTags && data.additionalMetaTags.map((tag, index) => (
            <meta key={index} {...tag} />
        ))}
    </Helmet>
);

SEOMetaTags.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        url: PropTypes.string,
        keywords: PropTypes.string,
        additionalMetaTags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                property: PropTypes.string,
                content: PropTypes.string,
            })
        )
    }).isRequired,
};

SEOMetaTags.defaultProps = {
    data: {
        title: 'Al Adwani Law Firm',
        description: 'Al Adwani Law Firm, a premier Law firm dedicated to delivering unparalleled legal services to our esteemed clients.',
        keywords: 'Al Adwani, law office, Law Firm, legal services, lawyer, legal assistance, expert legal advice, professional law firm',
        additionalMetaTags: [],
        url: '',
    }
};

export default SEOMetaTags;
