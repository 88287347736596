import React, { useEffect } from "react";

// mui
import { Box, Typography, Rating, Stack } from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { getTestimonialService } from "../../redux/services/general_service";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

import useResponsive from "../../hooks/useResponsive";

import { setTab } from "../../redux/slices/general";
//____________________________________________________

//---------- Styles -----------

const secondImgBoxStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center",
  alignItems: "center",
  justifyContent: "center",
  height: { lg: "auto", sm: "auto", xs: "auto" },
  width: "100%",
  position: "relative",
  pb: { xs: "18%", sm: "20px", lg: "40px" },
  pt: { xs: "30px", sm: "20px", lg: "50px" },
};

const secondoverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.9)",
  zIndex: 1,
};

const thirdoverlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(245, 245, 245, 0.4)",
  zIndex: 1,
};

const bigSecondWhiteTextStyle = {
  fontSize: { lg: "32px", xs: "24px", md: "32px", sm: "24px" },
  lineHeight: { lg: "24px", xs: "24px", md: "32px", sm: "24px" },
  fontWeight: 700,
  color: "rgba(255, 255, 255, 1)",
  mb: "20px",
  zIndex: 10,
  textAlign: "center",
  //mt:"20px"
};

const bigSecondBlueTextStyle = {
  fontSize: { lg: "32px", xs: "20px", md: "32px", sm: "20px" },
  lineHeight: { lg: "48px", xs: "31px", md: "48px", sm: "31px" },
  fontWeight: 700,
  color: "rgba(97, 204, 245, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "center",
};

const smallSecondBlueTextStyle = {
  fontSize: { lg: "20px", xs: "16px", sm: "16px" },
  lineHeight: { lg: "28px", xs: "28px", sm: "28px" },
  fontWeight: 500,
  color: "rgba(255, 255, 255, 1)",
  mb: "30px",
  zIndex: 10,
  textAlign: "center",
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
  zIndex: 10,
};

const whTextStyle = {
  fontSize: { lg: "20px", xs: "16px", md: "20px", sm: "16px" },
  lineHeight: { lg: "30px", xs: "27px", md: "27px", sm: "27px" },

  fontWeight: 700,
  color: "rgba(255, 255, 255, 1)",
  mb: "15px",
  zIndex: 10,
  textAlign: "justify",
  ///textAlign: "end",
};

const smallBlue = {
  fontSize: { lg: "18px", xs: "12px", md: "18px", sm: "12px" },
  lineHeight: { lg: "28px", xs: "28px", md: "28px", sm: "28px" },
  fontWeight: 400,
  color: "rgba(97, 204, 245, 1)",
};

//_______________________________________________________
export default function Jestimonials() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const { testimonialObj, testimonialPeopleList } = useSelector(
    (state) => state.general
  );

  useEffect(() => {
    dispatch(getTestimonialService());
  }, [dispatch]);

  return (
    <>
      <Box
        sx={{
          ...secondImgBoxStyle,
          backgroundImage: `url(${baseUrl}/${testimonialObj.image})`,
        }}
      >
        <Box sx={secondoverlayStyle}></Box>
        <Box sx={boxTitleStyle}>
          <Typography sx={bigSecondWhiteTextStyle}>
            {translate("testimonials")}
          </Typography>
          <img
            alt="Line icon"
            src="/assets/icons/Line 1.svg"
            style={{ zIndex: 10 }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            zIndex: 10,
            width: "100%",
            alignItems: "center",
            justifyContent: "Center",
          }}
        >
          <Typography sx={{ ...bigSecondBlueTextStyle, zIndex: 10 }}>
            {theme.direction === "ltr"
              ? testimonialObj.title_en
              : testimonialObj.title_ar}
            {}
          </Typography>
        </Box>
        <Box
          sx={{
            pr: { lg: "10%" },
            pl: { lg: "10%" },
            display: "flex",
            zIndex: 10,
            width: "100%",
            alignItems: "center",
            justifyContent: "Center",
          }}
        >
          <Typography
            sx={{ ...smallSecondBlueTextStyle, textAlign: "justify" }}
          >
            {theme.direction === "ltr"
              ? testimonialObj.details_en
              : testimonialObj.details_ar}
            {}
          </Typography>
        </Box>

        {/* Swiper*/}
        {(isDesktop || isSmallScreen || isMediumScreen) && (
          <div style={{ position: "relative" }}>
            <div
              className="custom-prev-2"
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                padding: { lg: "0 60px", sm: "0 10px" },
              }}
            >
              <img
                src="/assets/icons/circle-right-arrow 6.svg"
                alt="Previous"
              />
            </div>

            <div
              className="custom-next-2"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                padding: { lg: "0 60px", sm: "0 10px" },
              }}
            >
              <img src="/assets/icons/circle-right-arrow 5.svg" alt="Next" />
            </div>

            <Swiper
              //   style={isDesktop ? { padding: "0 20px" } : {}}
              modules={[Navigation]}
              navigation={{
                prevEl: ".custom-prev-2",
                nextEl: ".custom-next-2",
              }}
              spaceBetween={1}
              slidesPerView={1}
              Swiper
            >
              {testimonialPeopleList.map((item) => (
                <SwiperSlide key={item.id}>
                  <Box
                    sx={{
                      pl: { lg: "2%", sm: "4%" },
                      pr: { lg: "2%", sm: "4%" },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      p: "30px",
                      mx: "auto",
                      border: "1px solid rgba(97, 204, 245, 1)",
                      position: "relative",
                      width: { lg: "75%", xs: "90%", sm: "65%" },
                    }}
                  >
                    <Box sx={thirdoverlayStyle}></Box>
                    {/** coma */}

                    <Box
                      sx={{
                        zIndex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        // alignItems: "center",
                      }}
                    >
                      {/** coma */}
                      <img
                        alt="dot icon"
                        src="/assets/icons/dot.svg"
                        style={{ width: "15px", height: "30px" }}
                      />
                    </Box>

                    <Typography
                      sx={{
                        ...whTextStyle,
                        wordBreak: "break-word",
                      }}
                    >
                      {theme.direction === "ltr"
                        ? item.details_en
                        : item.details_ar}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        zIndex: 1,
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "21px", height: "30px", zIndex: 1 }}>
                        <img alt="dot icon" src="/assets/icons/dot.svg" />
                      </Box>
                      <Typography sx={smallBlue}>
                        {theme.direction === "ltr"
                          ? item.name_en
                          : item.name_ar}
                      </Typography>
                    </Box>

                    <Stack spacing={1} zIndex={10} margin={"auto"}>
                      <Rating
                        name="half-rating"
                        value={item.rate}
                        defaultValue={2.5}
                        precision={0.5}
                      />
                    </Stack>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {!isDesktop && !isSmallScreen && !isMediumScreen && (
          <>
            <div
              className="custom-prev"
              style={{
                position: "absolute",
                left: "35%",
                bottom: "20px",
                transform: "translateY(0)",
                zIndex: 10,
              }}
            >
              <img
                src="/assets/icons/circle-right-arrow 6.svg"
                alt="Previous"
              />
            </div>

            <div
              className="custom-next"
              style={{
                position: "absolute",
                right: "35%",
                bottom: "20px",
                transform: "translateY(0)", // No need to translate vertically
                zIndex: 10,
              }}
            >
              <img src="/assets/icons/circle-right-arrow 5.svg" alt="Next" />
            </div>

            <Swiper
              style={isDesktop ? { padding: "0 40px" } : {}}
              modules={[Navigation]}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              spaceBetween={1}
              slidesPerView={1}
            >
              {testimonialPeopleList.map((item) => (
                <SwiperSlide key={item.id}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid rgba(97, 204, 245, 1)",
                      position: "relative",
                      // height: { lg: "282px", sm: "282px", xs: "330px" },
                      width: { lg: "656px", xs: "75%" },
                      p: "30px",
                      mx: "auto",
                    }}
                  >
                    <Box sx={thirdoverlayStyle}></Box>

                    <Box
                      sx={{
                        zIndex: 1,
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                        // alignItems: "center",
                      }}
                    >
                      <img
                        alt="dot icon"
                        src="/assets/icons/dot.svg"
                        style={{ width: "15px", height: "30px" }}
                      />
                    </Box>

                    <Typography
                      sx={{ ...whTextStyle, wordBreak: "break-word" }}
                    >
                      {theme.direction === "ltr"
                        ? item.details_en
                        : item.details_ar}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        zIndex: 1,
                      }}
                    >
                      <Box sx={{ width: "21px", height: "30px", zIndex: 1 }}>
                        <img alt="dot icon" src="/assets/icons/dot.svg" />
                      </Box>
                      <Typography sx={smallBlue}>
                        {theme.direction === "ltr"
                          ? item.name_en
                          : item.name_ar}
                      </Typography>
                    </Box>

                    <Stack spacing={1} zIndex={10} margin={"auto"}>
                      <Rating
                        name="half-rating"
                        value={item.rate}
                        defaultValue={2.5}
                        precision={0.5}
                      />
                    </Stack>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}
      </Box>
    </>
  );
}
