import React from "react";

// mui
import { Box, Typography, Button, Grid } from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import useResponsive from "../../hooks/useResponsive";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

//____________________________________________________

//---------- Styles -----------

const secondGrid = {
  // display: "flex",
  // flexDirection: "column",
  background: "rgba(245, 245, 245, 1)",
  pl: { xs: "30px", sm: "0px" },
  pr: { xs: "30px", sm: "0px" },
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
  zIndex: 10,
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "5px",
  mt: "20px",
};

//_______________________________________________________
export default function ClientsPeople() {
  const { clientsList } = useSelector((state) => state.general);

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const isDesktop = useResponsive("up", "lg");

  const isMediumScreen = useResponsive("between", "md", "lg");

  return (
    <>
      <Box
        sx={{
          ...secondGrid,
          mt: "0px",
          backgroundColor: "#F5F5F5",
          pl: "20px",
          pr: "20px",
          pb: "40px",
        }}
      >
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>
            {translate("our_happy_clients")}
          </Typography>
          <img alt="line icon" src="/assets/icons/Line 1.svg" />
        </Box>

        <div style={{ position: "relative" }}>
          <div
            //className=".custom-prev"
            className="custom-prev-9"
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
            }}
          >
            <img src="/assets/icons/circle-right-arrow 6.svg" alt="Previous" />
          </div>

          <div
            className="custom-next-9"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
            }}
          >
            <img src="/assets/icons/circle-right-arrow 5.svg" alt="Next" />
          </div>

          <Swiper
            style={isDesktop || isMediumScreen ? { padding: "0 40px" } : {}}
            modules={[Navigation]}
            navigation={{
              prevEl: ".custom-prev-9",
              nextEl: ".custom-next-9",
            }}
            spaceBetween={2}
            //  slidesPerView={3}
            breakpoints={{
              1200: {
                slidesPerView: 5,
              },
              900: {
                slidesPerView: 4,
              },
              600: {
                slidesPerView: 2,
              },

              0: {
                slidesPerView: 1,
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                //  mx: "auto",
                width: "100%",
                pl: "100px",
              }}
            >
              {clientsList.map((item) => (
                <SwiperSlide key={item.id}>
                  <Box
                    sx={{
                      width: "115px",
                      height: "38px",
                      display: "flex",
                      margin: "auto",
                    }}
                  >
                    {" "}
                    <img alt="our client" src={`${baseUrl}/${item.image}`} />
                  </Box>
                </SwiperSlide>
              ))}
            </Box>
          </Swiper>
        </div>
      </Box>
    </>
  );
}
