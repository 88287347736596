import React, { useEffect } from "react";

// mui
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { getBlogsService } from "../../redux/services/general_service";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

import useResponsive from "../../hooks/useResponsive";

import { setTab } from "../../redux/slices/general";

import moment from "moment";
//____________________________________________________

//---------- Styles -----------

const secondGrid = {
  background: "rgba(245, 245, 245, 1)",
  pl: { xs: "30px", sm: "0px" },
  pr: { xs: "30px", sm: "0px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  lineHeight: { lg: "24px", sm: "24px", xs: "24px", md: "24px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "15px",
  mt: "40px",
};

const blackTitle = {
  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "20px", md: "20px", sm: "20px", xs: "20px" },
  fontWeight: 500,
  color: "#000000",
  textAlign: "justify",
};

const cardImgStyle = {
  // width: { lg: "344px", sm: "170px", xs: "100%", md: "352px" },
  height: { lg: "273px", sm: "130px", xs: "auto", md: "273px" },
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "70px" },
  zIndex: 10,
};

const buttonDateStyle = {
  color: "#FFFFFF",
  fontWeight: 700,
  fontSize: { xl: "16px", lg: "16px", xs: "12px" },
  bgcolor: "#1E184C",
  height: { xl: "34px", lg: "34px", xs: "34px" },
  width: "fit-content",
  borderRadius: "0px",
  zIndex: 10,
  mt: "20px",
  mb: { xs: "20px", sm: "0px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "20px", sm: "15px", xs: "15px" },
  bgcolor: "rgba(97, 204, 245, 1)",
  height: { lg: "48px", sm: "40px", xs: "40px" },
  width: { lg: "170px", sm: "142px", xs: "142px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: { lg: "20px", xs: "0px", sm: "5px" },
  mb: { xs: "20px", sm: "0px" },

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const bigColumnFlexBox = {
  display: "flex",
  borderRadius: 0,
  margin: "auto",
  pb: { xs: "66px", sm: "0px" },
  width: { lg: "364px", xs: "214px", sm: "220px" },
  mb: "40px",
  position: "relative",
  background: "rgba(255, 255, 255, 1)",
  boxShadow:
    "0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)",
  border: "1px solid #ADADAD",

  // flexDirection: "column",
  // alignItems: "center",
};

const cardContentSx = {
  height: { lg: "130px", sm: "90px", xs: "auto", md: "120px" },
  my: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // justifyContent: "center",
};

//_______________________________________________________
export default function Blogs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const { blogsList } = useSelector((state) => state.general);

  useEffect(() => {
    dispatch(
      getBlogsService({
        page: 1,
        page_size: 10,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Box sx={{ ...secondGrid, mt: "0px", backgroundColor: "#FFFFFF" }}>
        <Box sx={boxTitleStyle}>
          <Typography sx={titleStyle}>{translate("blogs")}</Typography>
          <img alt="Line icon" src="/assets/icons/Line 1.svg" />
        </Box>

        <div style={{ position: "relative" }}>
          <div
            className="custom-prev-4"
            style={{
              position: "absolute",
              left: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              padding: "0 10px",
            }}
          >
            <img src="/assets/icons/circle-right-arrow 6.svg" alt="Previous" />
          </div>

          <div
            className="custom-next-4"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 10,
              padding: "0 10px",
            }}
          >
            <img src="/assets/icons/circle-right-arrow 5.svg" alt="Next" />
          </div>

          <Swiper
            style={
              isDesktop
                ? { padding: "0 35px" }
                : isSmallScreen
                ? { padding: "0 20px" }
                : {}
            }
            modules={[Navigation]}
            navigation={{
              prevEl: ".custom-prev-4",
              nextEl: ".custom-next-4",
            }}
            spaceBetween={10}
            //  slidesPerView={3}
            breakpoints={{
              1200: {
                slidesPerView: 3,
              },

              900: {
                slidesPerView: 2,
              },

              600: {
                slidesPerView: 2,
              },

              0: {
                slidesPerView: 1,
              },
            }}
          >
            {blogsList.map((item) => (
              <SwiperSlide key={item.id}>
                <Card className="custom-card" sx={{ ...bigColumnFlexBox }}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      sx={cardImgStyle}
                      image={`${baseUrl}/${item.image}`}
                      alt={"blog image"}
                    />
                    <CardContent sx={cardContentSx}>
                      <Typography
                        sx={{
                          ...blackTitle,
                          display: "-webkit-box",

                          WebkitBoxOrient: "vertical",

                          overflow: "hidden",

                          WebkitLineClamp: 4, // Limit to 3 lines

                          textOverflow: "ellipsis",
                        }}
                      >
                        {theme.direction === "ltr"
                          ? item.details_en
                          : item.details_ar}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: { lg: "63%", xs: "55%", sm: "51%", md: "66%" },
                    }}
                  >
                    <Button
                      fullWidth
                      disableRipple
                      size="large"
                      type="submit"
                      variant="Contained"
                      sx={{ ...buttonDateStyle, margin: "auto" }}
                    >
                      {moment(item.created_at).format("DD-MM-YYYY")}
                    </Button>
                  </Box>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Button
            fullWidth
            disableRipple
            size="large"
            type="submit"
            variant="contained"
            sx={{ ...buttonAiStyle, margin: "auto" }}
            onClick={(event) => {
              navigate(`/blogs`);
              dispatch(setTab("4"));
            }}
          >
            {translate("explore_more")}
          </Button>
        </Box>

        <Box sx={{ pb: "20px" }}></Box>
      </Box>
    </>
  );
}
