import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

export const getAboutUsService = createAsyncThunk(
  "general/getAboutUsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.aboutUsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAboutUsHomeService = createAsyncThunk(
  "general/getAboutUsHomeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.aboutUsHomeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getBlogsService = createAsyncThunk(
  "general/getBlogsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.blogsURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getClientsService = createAsyncThunk(
  "general/getClientsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.clientsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPracticeTopicService = createAsyncThunk(
  "general/getPracticeTopicService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.practiceTopicURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getTestimonialService = createAsyncThunk(
  "general/getTestimonialService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.testimonialURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getTestimonialPeopleService = createAsyncThunk(
  "general/getTestimonialPeopleService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.testimonialPeopleURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPeopleService = createAsyncThunk(
  "general/getPeopleService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getPeopleURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPeopleTypeService = createAsyncThunk(
  "general/getPeopleTypeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getPeopleTypeURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPracticeContentService = createAsyncThunk(
  "general/getPracticeContentService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.practiceTopicURL}${params.id}/contents/`,
        {
          params: {
            ...params,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getkeyPracticeService = createAsyncThunk(
  "general/getkeyPracticeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getkeyPracticeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getOfficeInfoService = createAsyncThunk(
  "general/getOfficeInfoService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getOfficeInfoURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createJobService = createAsyncThunk(
  "general/createJobService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createJobsURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createContactUsService = createAsyncThunk(
  "general/createContactUsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createContactUsURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getSlidersService = createAsyncThunk(
  "general/getSlidersService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSlidersHomeURL, {
        params: {
          ...params,
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPrivacyService = createAsyncThunk(
  "general/getPrivacyService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getPrivacyURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getTermsService = createAsyncThunk(
  "general/getTermsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getTermsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCookieService = createAsyncThunk(
  "general/getCookieService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getCookieURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
