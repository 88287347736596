import React, { useCallback, useEffect, useState } from "react";

import { Box, Typography, Grid } from "@mui/material";

import { getBlogsService } from "../../redux/services/general_service";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import {allLangs, useLocales} from "../../locales";

import { useTheme } from "@mui/material/styles";

import InfiniteScroll from "react-infinite-scroll-component";

import useResponsive from "../../hooks/useResponsive";

// Footer
import FooterSection from "../footer/FooterSection";

//--- seo ---
import { setMetaData } from "../../redux/slices/seoSlice";
import { blogsMetaData } from "../../config/metaData";
import { getCurrentLanguage } from "../../utils/helper";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  alignItems: "center",
  height: "667px",
  width: "100%",
  position: "relative",
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pl: "30px",
  pr: "30px",
  mb: "50px",
  mt: { lg: "163px", xs: "150px", md: "160px", sm: "145px" },
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "54px", sm: "54px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  // textAlign: "center",
};

const leftGridStyle = {
  display: "flex",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
  pl: { lg: "100px", xs: "10px", md: "100px", sm: "0px" },
  mt: { lg: "30px", sm: "0px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  // textAlign: "end",
  mb: "5px",
  mt: { lg: "20px" },
  //pr: { lg: "0px", xs: "40px", md: "0px", sm: "0px" },
};

const blueTitleStyle = {
  fontSize: { lg: "32px", sm: "20px", xs: "20px", md: "32px" },
  fontWeight: 700,
  color: "#61CCF5",
  //textAlign: "end",
  mb: "5px",
  mt: { lg: "10px" },
  //pr: { lg: "0px", xs: "40px", md: "0px", sm: "0px" },
};

const blackTextStyle = {
  fontSize: { lg: "20px", xs: "16px", md: "20px", sm: "14px" },
  lineHeight: { lg: "32px", xs: "26px", md: "32px", sm: "26px" },
  fontWeight: 600,
  color: "#2A2555",
  textAlign: "justify",
  mt: "10px",
};

const imgStyle = {
  width: { lg: "492px", sm: "282px", xs: "100%", md: "492px" },
  height: { lg: "444px", sm: "331px", xs: "277px", md: "444px" },
  margin: "auto",
  mb: { xs: "20px", sm: "0px" },
};

const lineStyle = {
  width: "125px",
  zIndex: 10,
  display: "flex",
  //justifyContent: "flex-end",
};

const gridStyle = {
  pl: "30px",
  pr: "30px",
};
//___________________________________________________________

export default function NewsInsightstab() {
  const dispatch = useDispatch();

  const isDesktop = useResponsive("up", "lg");

  const isXsScreen = useResponsive("down", "sm");

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const [hasMore, setHasMore] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const [hasMoreXs, setHasMoreXs] = useState(true);

  const [currentPageXs, setCurrentPageXs] = useState(1);

  const [hasMoreSm, setHasMoreSm] = useState(true);

  const [currentPageSm, setCurrentPageSm] = useState(1);

  const { translate } = useLocales();

  const theme = useTheme();

  const navigate = useNavigate();

  const {
    blogsList,
    blogsNext,
    blogsLastPage,
    isLoadingBlogs,
    //
    blogsListSm,
    blogsNextSm,
    blogsLastPageSm,
    //
    blogsListXs,
    blogsNextXs,
    blogsLastPageXs,
  } = useSelector((state) => state.general);

  const currentLanguage = getCurrentLanguage(allLangs);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(blogsMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  const [aboutUs, setAboutUsList] = useState(blogsList || []);

  const [aboutUsXs, setAboutUsListXs] = useState(blogsListXs || []);

  const [aboutUsSm, setAboutUsListSm] = useState(blogsListSm || []);

  useEffect(() => {
    if (blogsList && blogsList.length > 0) {
      setAboutUsList((prev) => [...prev, ...blogsList]);
      setHasMore(blogsNext && aboutUs.length < blogsLastPage);
    }
  }, [blogsList, blogsNext, blogsLastPage]);

  useEffect(() => {
    if (blogsListSm && blogsListSm.length > 0) {
      setAboutUsListSm((prev) => [...prev, ...blogsListSm]);

      setHasMoreSm(blogsNextSm && aboutUsSm.length < blogsLastPageSm);
    }
  }, [blogsListSm, blogsNextSm, blogsLastPageSm]);

  useEffect(() => {
    if (blogsListXs && blogsListXs.length > 0) {
      setAboutUsListXs((prev) => [...prev, ...blogsListXs]);

      setHasMoreXs(blogsNextXs && aboutUsXs.length < blogsLastPageXs);
    }
  }, [blogsListXs, blogsNextXs, blogsLastPageXs]);

  const handleLoadMoreClients = () => {
    if (hasMore && !isLoadingBlogs) {
      const nextPage = currentPage + 1;

      setCurrentPage(nextPage);

      dispatch(getBlogsService({ page: nextPage, page_size: 10 })).then(
        (res) => {
          if (res.payload.data.data.length === 0) {
            setHasMore(false);
          }
        }
      );
    }
  };

  const handleLoadMoreClientsSm = () => {
    if (hasMoreSm && !isLoadingBlogs) {
      const nextPage = currentPageSm + 1;

      setCurrentPageSm(nextPage);

      dispatch(getBlogsService({ page: nextPage, page_size: 10 })).then(
        (res) => {
          if (res.payload.data.data.length === 0) {
            setHasMoreSm(false);
          }
        }
      );
    }
  };

  const handleLoadMoreClientsXs = () => {
    if (hasMoreXs && !isLoadingBlogs) {
      const nextPage = currentPageXs + 1;

      setCurrentPageXs(nextPage);

      dispatch(getBlogsService({ page: nextPage, page_size: 10 })).then(
        (res) => {
          if (res.payload.data.data.length === 0) {
            setHasMoreXs(false);
          }
        }
      );
    }
  };

  useEffect(() => {
    setAboutUsList([]);

    setCurrentPage(1);

    setHasMore(true);

    setAboutUsListSm([]);

    setCurrentPageSm(1);

    setHasMoreSm(true);

    setAboutUsListXs([]);

    setCurrentPageXs(1);

    setHasMoreXs(true);

    dispatch(
      getBlogsService({
        page: 1,
        page_size: 10,
      })
    );
  }, []);

  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}></Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={bigWhiteTextStyle}>{translate("blogs")}</Typography>
          <Box
            sx={{
              width: "66px",
              zIndex: 10,
              display: "flex",
            }}
          >
            <img alt="Line icon" src="/assets/icons/Line 1.svg" />
          </Box>
        </Box>
      </Box>

      {isDesktop && (
        <InfiniteScroll
          dataLength={aboutUs.length}
          next={handleLoadMoreClients}
          hasMore={hasMore}
          loader={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography mb="30px">loading ...</Typography>
            </Box>
          } // Use ClipLoader here
          endMessage={
            <p style={{ textAlign: "center", ...titleStyle }}>
              <b>{translate("you_have_seen_it_all")}</b>
            </p>
          }
        >
          <Grid container columnSpacing={2} sx={gridStyle}>
            {aboutUs.map((item, index) => {
              const isEven = index % 2 === 0;
              return (
                <>
                  {isEven ? (
                    <>
                      <Grid item xs={12} sm={6} mb="30px">
                        <Box sx={leftGridStyle}>
                          {/* Split the title into two parts */}

                          <Typography sx={{ ...blueTitleStyle }}>
                            {theme.direction === "ltr"
                              ? item.title_en
                              : item.title_ar}
                            {}
                          </Typography>

                          <Box sx={lineStyle}>
                            <img
                              alt="Line icon"
                              src="/assets/icons/Line 1.svg"
                            />
                          </Box>

                          <Typography sx={{ ...blackTextStyle }}>
                            {theme.direction === "ltr"
                              ? item.details_en
                              : item.details_ar}
                            {}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} mb="30px">
                        <Box
                          component="img"
                          src={`${baseUrl}/${item.image}`}
                          sx={imgStyle}
                          alt={"blog image"}
                        ></Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} mb="30px">
                        <Box
                          component="img"
                          src={`${baseUrl}/${item.image}`}
                          sx={imgStyle}
                          alt={"blog image"}
                        ></Box>
                      </Grid>

                      <Grid item xs={12} sm={6} mb="30px">
                        {/* Split the title into two parts */}
                        <Box sx={leftGridStyle}>
                          <Typography sx={{ ...blueTitleStyle }}>
                            {theme.direction === "ltr"
                              ? item.title_en
                              : item.title_ar}
                            {}
                          </Typography>

                          <Box sx={lineStyle}>
                            <img
                              alt="Line icon"
                              src="/assets/icons/Line 1.svg"
                            />
                          </Box>
                          <Typography sx={blackTextStyle}>
                            <Typography sx={{ ...blackTextStyle }}>
                              {theme.direction === "ltr"
                                ? item.details_en
                                : item.details_ar}
                              {}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}

      {!isDesktop && !isXsScreen && (
        <InfiniteScroll
          dataLength={aboutUsSm.length}
          next={handleLoadMoreClientsSm}
          hasMore={hasMoreSm}
          loader={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography mb="30px">loading ...</Typography>{" "}
            </Box>
          }
          endMessage={
            <p style={{ textAlign: "center", ...titleStyle }}>
              <b>{translate("you_have_seen_it_all")}</b>
            </p>
          }
        >
          <Grid container columnSpacing={2} sx={gridStyle}>
            {aboutUsSm.map((item, index) => {
              const isEven = index % 2 === 0;
              return (
                <>
                  {isEven ? (
                    <>
                      <Grid item xs={12} sm={6} mb="30px">
                        <Box sx={leftGridStyle}>
                          <Typography sx={{ ...blueTitleStyle }}>
                            {theme.direction === "ltr"
                              ? item.title_en
                              : item.title_ar}
                            {}
                          </Typography>

                          <Box sx={lineStyle}>
                            <img
                              alt="Line icon"
                              src="/assets/icons/Line 1.svg"
                            />
                          </Box>

                          <Typography sx={{ ...blackTextStyle }}>
                            {theme.direction === "ltr"
                              ? item.details_en
                              : item.details_ar}
                            {}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6} mb="30px">
                        <Box
                          component="img"
                          src={`${baseUrl}/${item.image}`}
                          sx={imgStyle}
                          alt={"blog image"}
                        ></Box>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6} mb="30px">
                        <Box
                          component="img"
                          src={`${baseUrl}/${item.image}`}
                          sx={imgStyle}
                          alt={"blog image"}
                        ></Box>
                      </Grid>

                      <Grid item xs={12} sm={6} mb="30px">
                        <Box sx={leftGridStyle}>
                          <Typography sx={{ ...blueTitleStyle }}>
                            {theme.direction === "ltr"
                              ? item.title_en
                              : item.title_ar}
                            {}
                          </Typography>

                          <Box sx={lineStyle}>
                            <img
                              alt="Line icon"
                              src="/assets/icons/Line 1.svg"
                            />
                          </Box>
                          <Typography sx={blackTextStyle}>
                            <Typography sx={{ ...blackTextStyle }}>
                              {theme.direction === "ltr"
                                ? item.details_en
                                : item.details_ar}
                              {}
                            </Typography>
                          </Typography>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}

      {isXsScreen && (
        <InfiniteScroll
          dataLength={aboutUsXs.length}
          next={handleLoadMoreClientsXs}
          hasMore={hasMoreXs}
          loader={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography mb="30px">loading ...</Typography>{" "}
            </Box>
          }
          endMessage={
            <p style={{ textAlign: "center", ...titleStyle }}>
              <b>{translate("you_have_seen_it_all")}</b>
            </p>
          }
        >
          <Grid container columnSpacing={2} sx={gridStyle}>
            {aboutUsXs.map((item, index) => {
              return (
                <>
                  <Grid item xs={12} sm={6} mb="30px">
                    <Box sx={leftGridStyle}>
                      <Typography sx={{ ...blueTitleStyle }}>
                        {theme.direction === "ltr"
                          ? item.title_en
                          : item.title_ar}
                        {}
                      </Typography>

                      <Box sx={lineStyle}>
                        <img alt="Line icon" src="/assets/icons/Line 1.svg" />
                      </Box>

                      <Typography sx={{ ...blackTextStyle }}>
                        {theme.direction === "ltr"
                          ? item.details_en
                          : item.details_ar}
                        {}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} mb="30px">
                    <Box
                      component="img"
                      src={`${baseUrl}/${item.image}`}
                      sx={imgStyle}
                      alt={"blog image"}
                    ></Box>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}
      <FooterSection />
    </>
  );
}
