import { Suspense, lazy } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------
// HomePage
export const HomePage = Loadable(lazy(() => import("../pages/HomePage")));

// HomeTab
export const HomeTab = Loadable(lazy(() => import("../sections/tabs/HomeTab")));

// AboutUsTab
export const AboutUsTab = Loadable(
  lazy(() => import("../sections/tabs/AboutUsTab"))
);

// PracticeAreasTab
export const PracticeAreasTab = Loadable(
  lazy(() => import("../sections/tabs/PracticeAreasTab"))
);

// OurPeopleTab
export const OurPeopleTab = Loadable(
  lazy(() => import("../sections/tabs/OurPeopleTab"))
);

// NewsInsightstab
export const NewsInsightstab = Loadable(
  lazy(() => import("../sections/tabs/NewsInsightstab"))
);

// CareersTab
export const CareersTab = Loadable(
  lazy(() => import("../sections/tabs/CareersTab"))
);

//PracticeAreaDetails
export const PracticeAreaDetails = Loadable(
  lazy(() => import("../sections/tabs/PracticeAreaDetails"))
);

//CookiePage
export const CookiePage = Loadable(lazy(() => import("../pages/CookiePage")));

//PrivacyPage
export const PrivacyPage = Loadable(lazy(() => import("../pages/PrivacyPage")));

//CookiePage
export const TermsPage = Loadable(lazy(() => import("../pages/TermsPage")));
