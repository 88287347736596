import React, { useCallback, useEffect } from "react";

import { Box, Typography, Grid } from "@mui/material";

import OurPeopleCard from "../../components/ui-component/OurPeopleCard";

import { useDispatch, useSelector } from "react-redux";

import { useLocales } from "../../locales";

import { getPeopleTypeService } from "../../redux/services/general_service";

import { useTheme } from "@mui/material/styles";

import FooterSection from "../footer/FooterSection";

//--- seo ---
import {setMetaData} from "../../redux/slices/seoSlice";
import {peopleMetaData} from "../../config/metaData";
import {getCurrentLanguage} from "../../utils/helper";

//_________________________________________________________

//* ------- Styles --------

const firstBoxStyle = {
  backgroundImage: "url('/assets/images/back.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  //flexDirection: "column",
  alignItems: "center",
  //justifyContent: "flex-end",
  height: "667px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
  pl: "30px",
  pr: "30px",
  mb: "50px",
  mt: { lg: "163px", xs: "150px", md: "160px", sm: "145px" },
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(30, 24, 76, 0.5)",
  zIndex: 1,
};

const bigWhiteTextStyle = {
  fontSize: { lg: "54px", sm: "54px", xs: "32px" },
  fontWeight: { lg: 700, xs: 700 },
  color: "#FFFFFF",
  mb: "15px",
  zIndex: 10,
  // textAlign: "center",
};

const gridStyle = {
  pl: { xs: "20px", lg: "0px" },
  pr: { xs: "20px", lg: "0px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "5px",
  mt: "20px",
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "20px" },
};

//___________________________________________________________
export default function OurPeopleTab() {
  const theme = useTheme();

  const {translate, allLangs} = useLocales();
  const currentLanguage = getCurrentLanguage(allLangs);

  const { peopleTypeList } = useSelector((state) => state.general);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPeopleTypeService({
        page: 1,
        page_size: 10,
      })
    );
  }, []);

  useEffect(() => {
    currentLanguage &&
    dispatch(setMetaData(peopleMetaData[currentLanguage.value]));
  }, [dispatch, currentLanguage]);

  //________________________________________

  return (
    <>
      {/** first sections  */}
      <Box sx={firstBoxStyle}>
        <Box sx={overlayStyle}></Box>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={bigWhiteTextStyle}>
            {translate("our_people")}
          </Typography>
          <Box
            sx={{
              width: "66px",
              zIndex: 10,
              display: "flex",
            }}
          >
            <img alt="line icon" src="/assets/icons/Line 1.svg" />
          </Box>
        </Box>
      </Box>

      {peopleTypeList.map((item) => (
        <>
          <Box sx={boxTitleStyle}>
            <Typography sx={titleStyle}>
              {" "}
              {theme.direction === "ltr" ? item.title_en : item.title_ar}
            </Typography>
            <img alt="line icon" src="/assets/icons/Line 1.svg" />
          </Box>

          <Grid container columnSpacing={1} mt="20px" pb="20px" sx={gridStyle}>
            {item.people?.map((inner) => (
              <Grid item xs={12} sm={6} lg={4} key={inner.id}>
                <OurPeopleCard item={inner} />
              </Grid>
            ))}
          </Grid>
        </>
      ))}
      <FooterSection />
    </>
  );
}
