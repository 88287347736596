import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    title: 'Al Adwani Law Firm',
    description: 'Al Adwani Law Firm, a premier Law firm dedicated to delivering unparalleled legal services to our esteemed clients. ' +
        'Established in 1991 by Mr. Ayyad Al Adwani, ' +
        'our firm has built a distinguished reputation through innovative methodologies ' +
        'and unwavering dedication to client satisfaction.',
    keywords: 'Al Adwani, law office, Law Firm, legal services, lawyer, legal assistance, expert legal advice, professional law firm',
    url: '',
    additionalMetaTags: [],
};

const seoSlice = createSlice({
    name: 'seo',
    initialState,
    reducers: {
        setMetaData: (state, action) => {
            return {...state, ...action.payload};
        },
    },
});

export const {setMetaData} = seoSlice.actions;
export default seoSlice.reducer;
