import React, { useEffect, useState } from "react";

// mui
import {
  Box,
  Typography,
  Grid,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { setTab } from "../../redux/slices/general";

import { Link } from "react-scroll";

import { useLocation } from "react-router-dom";

//____________________________________________________

//---------- Styles -----------

const footerContainer = {
  p: { lg: "20px 60px 20px 60px", sm: "30px", md: "30px", xs: "20px" },
  background: "rgba(30, 24, 76, 1)",
  marginBottom: 0,
};

const contentStyle = {
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  fontWeight: { lg: 500, sm: 500 },
  color: "rgba(251, 251, 251, 1)",

  //textAlign: "end",
};

const lightContentStyle = {
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  fontWeight: { lg: 400, sm: 400 },
  color: "rgba(251, 251, 251, 1)",
  //textAlign: "end",
};

const lastTextStyle = {
  fontSize: { lg: "14px", sm: "10px", xs: "10px" },
  fontWeight: { lg: 600, sm: 600, xs: 600 },
  color: "rgba(255, 255, 255, 1)",
  cursor: "pointer",
  lineHeight: "25px",
};

const smallTextStyle = {
  fontSize: { lg: "18px", sm: "14px", xs: "14px" },
  fontWeight: { lg: 600, sm: 500 },
  color: "rgba(97, 204, 245, 1)",
  mb: "10px",
};

const flexBox = {
  display: "flex",
  alignItems: "center",
  mb: "4px",
  cursor: "pointer",
};

const flexBoxSpaceBetwee = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
};

const innerFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "50%",
  //margin: "auto",
};

const imgStyle = {
  width: { lg: "249px", sm: "131px", xs: "131px" },
  height: { lg: "137px", sm: "72px", xs: "72px" },
};

const iconCallStyle = {
  width: { lg: "24px", sm: "14.23px", xs: "14.23px" },
  height: { lg: "24px", sm: "14.23px", xs: "14.23px" },
  flexShrink: 0,
};

const dotStyle = {
  width: "5px",
  height: "5px",
  borderRadius: "50%",
  backgroundColor: "White",
  ml: "10px",
  mr: "10px",
};

const flexBoxColumn = {
  display: "flex",
  //alignItems: "center",
  flexDirection: "column",
  margin: { lg: "auto", sm: "none" },
};

const flexBoxStart = {
  display: "flex",
  mb: "15px",
};

const dividerStyle = {
  mt: "15px",
  mb: "15px",
  color: "rgba(255, 255, 255, 1)",
};

const iconSocialStyle = {
  width: { lg: "20px", sm: "11.86px", xs: "11.86px" },
  height: { lg: "20px", sm: "11.86px", xs: "11.86px" },
};
//_______________________________________________________
export default function FooterSection() {
  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const currentYear = new Date().getFullYear(); // Get the current year

  const theme = useTheme();

  const { officeInfo } = useSelector((state) => state.general);

  useEffect(() => {
    const currentPath = location.pathname;

    const footerPaths = [
      "/cookie-policy",

      "/terms-conditions",

      "/privacy-policy",
    ];

    // Check if the current path is one of the footer paths

    if (footerPaths.includes(currentPath)) {
      dispatch(setTab(null)); // Set the tab to null in Redux
    } else {
      // Logic to set the tab based on other routes

      // For example, you can set the tab based on the pathname

      if (currentPath === "/home") {
        dispatch(setTab("0"));
      } else if (currentPath === "/about-us") {
        dispatch(setTab("1"));
      } else if (currentPath === "/practice-areas") {
        dispatch(setTab("2"));
      } else if (currentPath === "/our-people") {
        dispatch(setTab("3"));
      } else if (currentPath === "/blogs") {
        dispatch(setTab("4"));
      } else if (currentPath === "/careers") {
        dispatch(setTab("5"));
      }
    }
  }, [location.pathname, dispatch]);

  const handleContactUsClick = () => {
    if (location.pathname !== "/home") {
      // Navigate to the Home tab

      navigate("/home");

      dispatch(setTab("0"));

      // Use a timeout to allow the navigation to complete before scrolling

      setTimeout(() => {
        // Scroll to the contactUs section

        const contactUsElement = document.getElementById("contactUs");

        if (contactUsElement) {
          contactUsElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the timeout duration as needed
    } else {
      // If already on the Home tab, just scroll to the contactUs section

      const contactUsElement = document.getElementById("contactUs");

      if (contactUsElement) {
        contactUsElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/home") {
      navigate("/home");

      dispatch(setTab("0"));
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = () => {
    window.location.href = `tel:${officeInfo.phone_number}`;

    handleClose();
  };

  const handleMessage = () => {
    window.location.href = `sms:${officeInfo.phone_number}`;
    handleClose();
  };

  return (
    <>
      <Box sx={footerContainer}>
        <Grid container columnSpacing={{ xs: 2, md: 2, sm: 2 }}>
          {/** logo */}
          <Grid item xs={12} sm={3}>
            <Box onClick={handleLogoClick} sx={imgStyle}>
              <img
                src={`${baseUrl}/${officeInfo.logo}`}
                alt="office Info logo"
              />
            </Box>
          </Grid>

          {/** logo */}
          <Grid item xs={12} sm={9}></Grid>

          <Grid item xs={6} sm={4}>
            <Box sx={{ ...flexBoxColumn }}>
              <Typography
                sx={{
                  ...contentStyle,
                  lineHeight: "30px",
                  textAlign: "justify",
                }}
              >
                {theme.direction === "ltr"
                  ? officeInfo.details_en
                  : officeInfo.details_ar}
              </Typography>
              <Box
                sx={{
                  ...innerFlexBoxStyle,
                  mt: "35px",
                  mb: { xs: "20px", sm: "0px" },
                }}
              >
                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/twitter.svg" alt="twitter icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/linkedin.svg" alt="linkedin icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/facebook.svg" alt="facebook icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/icons/instagram.svg"
                      alt="instagram icon"
                    />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/watsup.svg" alt="whatsup icon" />
                  </a>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={6}
            sm={4}
            sx={{
              ...flexBoxColumn,
            }}
          >
            <Box sx={{ ...flexBoxColumn }}>
              <Typography sx={{ ...smallTextStyle, lineHeight: "24px" }}>
                {translate("quick_Links")}
              </Typography>

              <Box
                onClick={() => {
                  navigate("/about-us");
                }}
                sx={flexBox}
              >
                <Box sx={dotStyle}></Box>
                <Typography sx={{ ...contentStyle, lineHeight: "24px" }}>
                  {translate("about_us")}
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  navigate("/practice-areas");
                }}
                sx={flexBox}
              >
                <Box sx={dotStyle}></Box>
                <Typography sx={{ ...contentStyle, lineHeight: "24px" }}>
                  {" "}
                  {translate("key_practice_areas")}
                </Typography>
              </Box>

              <Box
                onClick={() => {
                  navigate("/blogs");
                }}
                sx={flexBox}
              >
                <Box sx={dotStyle}></Box>
                <Typography sx={{ ...contentStyle, lineHeight: "24px" }}>
                  {translate("blogs")}
                </Typography>
              </Box>

              <Link
                to="contactUs" // This should match the name in the Element in HomeTab
                smooth={true}
                duration={500}
                onClick={handleContactUsClick} // Use the click handler
                style={{ cursor: "pointer", textDecoration: "none" }} // Add cursor pointer for better UX
                href={"/home"}
              >
                <Box sx={flexBox}>
                  <Box sx={dotStyle}></Box>

                  <Typography sx={{ ...contentStyle, lineHeight: "24px" }}>
                    {translate("contact_us")}
                  </Typography>
                </Box>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Box sx={{ ...flexBoxColumn }}>
              {/** location */}
              <Box sx={{ ...flexBoxStart }}>
                <Box sx={{ ...iconCallStyle, mt: "4px" }}>
                  <img src="/assets/icons/location.svg" alt="location icon" />
                </Box>
                <Typography
                  sx={{
                    ...contentStyle,
                    textAlign: "justify",
                    pr: "5px",
                    pl: "5px",
                    lineHeight: "20px",
                  }}
                >
                  {theme.direction === "ltr"
                    ? officeInfo.address_en
                    : officeInfo.address_ar}
                </Typography>
              </Box>

              {/** phone_number */}
              <Box
                onClick={handleClickOpen}
                sx={{
                  ...flexBoxStart,
                }}
              >
                <Box sx={iconCallStyle}>
                  <img src="/assets/icons/call.svg" alt="call icon" />
                </Box>

                {officeInfo && officeInfo.phone_number ? (
                  theme.direction === "rtl" ? (
                    <Box
                      onClick={handleClickOpen}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        sx={{
                          ...lightContentStyle,
                          pr: "5px",
                          pl: "5px",
                          lineHeight: "20px",
                        }}
                      >
                        {`${officeInfo.phone_number.slice(1)}`}{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { lg: "16px", sm: "12px" },
                          fontWeight: 500,
                          color: "rgba(255, 255, 255, 1)",
                        }}
                      >
                        +
                      </Typography>
                    </Box>
                  ) : (
                    <Typography
                      onClick={handleClickOpen}
                      sx={{
                        ...lightContentStyle,
                        pr: "5px",
                        pl: "5px",
                        lineHeight: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {officeInfo.phone_number}
                    </Typography>
                  )
                ) : (
                  <Typography
                    sx={{
                      ...lightContentStyle,
                      pr: "5px",
                      pl: "5px",
                      lineHeight: "20px",
                    }}
                  ></Typography>
                )}
              </Box>

              {/**email */}
              <a
                href={`mailto:${officeInfo.email}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Box sx={{ ...flexBoxStart }}>
                  <Box sx={iconCallStyle}>
                    <img src="/assets/icons/mail.svg" alt="mail icon" />
                  </Box>

                  <Typography
                    sx={{
                      ...lightContentStyle,
                      pr: "5px",
                      pl: "5px",
                      lineHeight: "20px",
                    }}
                  >
                    {officeInfo.email}
                  </Typography>
                </Box>
              </a>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider sx={dividerStyle} />
          </Grid>

          <Grid item xs={12} sm={4.5} mt={{ xs: "10px", sm: "0px" }}>
            <Box sx={flexBoxSpaceBetwee}>
              <Typography
                onClick={() => {
                  setTab(null);
                  navigate("/cookie-policy");
                }}
                sx={lastTextStyle}
              >
                {translate("cookie")}
              </Typography>

              <Typography
                onClick={() => navigate("/terms-conditions")}
                sx={lastTextStyle}
              >
                {translate("terms")}
              </Typography>

              <Typography
                onClick={() => navigate("/privacy-policy")}
                sx={lastTextStyle}
              >
                {translate("privacy")}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={3}></Grid>

          <Grid item xs={12} sm={4.5}>
            <Typography sx={{ ...lastTextStyle, textAlign: "center" }}>
              © {currentYear} Aladwani Law firm. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate("options")}</DialogTitle>

        <DialogContent>
          <Typography>{translate("how")}</Typography>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCall} color="primary">
            {translate("call")}
          </Button>

          <Button onClick={handleMessage} color="primary">
            {translate("message")}
          </Button>

          <Button onClick={handleClose} color="secondary">
            {translate("cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
