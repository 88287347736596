import { Navigate, useRoutes } from "react-router-dom";

import {
  HomeTab,
  AboutUsTab,
  PracticeAreasTab,
  OurPeopleTab,
  NewsInsightstab,
  CareersTab,
  PracticeAreaDetails,
  CookiePage,
  PrivacyPage,
  TermsPage,
} from "./elements";

import DashboardLayout from "../layouts/dashboard/DashboardLayout";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",

      element: <DashboardLayout />,

      children: [
        { element: <Navigate to="/home" replace />, index: true },

        {
          path: "home",

          element: <HomeTab />,
        },

        {
          path: "about-us",

          element: <AboutUsTab />,
        },

        {
          path: "practice-areas",

          element: <PracticeAreasTab />,
        },

        {
          path: "our-people",

          element: <OurPeopleTab />,
        },

        {
          path: "blogs",

          element: <NewsInsightstab />,
        },

        {
          path: "careers",

          element: <CareersTab />,
        },

        {
          path: "cookie-policy",

          element: <CookiePage />,
        },

        {
          path: "terms-conditions",

          element: <TermsPage />,
        },

        {
          path: "privacy-policy",

          element: <PrivacyPage />,
        },

        { path: ":headerTitle", element: <PracticeAreaDetails /> }, // Dynamic route for PracticeAreaDetails
      ],
    },
  ]);
}
