import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";

import { useTheme } from "@mui/material/styles";

import {
  Stack,
  AppBar,
  Toolbar,
  Box,
  Typography,
  Tabs,
  Tab,
  Link,
} from "@mui/material";

import useOffSetTop from "../../../hooks/useOffSetTop";

import useResponsive from "../../../hooks/useResponsive";

import { HEADER, NAV } from "../../../config";

import { useSettingsContext } from "../../../components/settings";

import { useLocales } from "../../../locales";

import { useDispatch, useSelector } from "react-redux";

// Tabs
import HomeTab from "../../../sections/tabs/HomeTab";

import AboutUsTab from "../../../sections/tabs/AboutUsTab";

import CareersTab from "../../../sections/tabs/CareersTab";

import NewsInsightstab from "../../../sections/tabs/NewsInsightstab";

import OurPeopleTab from "../../../sections/tabs/OurPeopleTab";

import PracticeAreasTab from "../../../sections/tabs/PracticeAreasTab";

import { useNavigate, useLocation } from "react-router-dom";

import { setTab } from "../../../redux/slices/general";

import {
  getOfficeInfoService,
  getPrivacyService,
  getTermsService,
  getCookieService,
} from "../../../redux/services/general_service";

// ----------------------------------------------------------------------

const TABS = [
  {
    value: "0",
    label: "home",
    path: "home",
    component: <HomeTab />,
  },
  {
    value: "1",
    label: "about_us",
    path: "about-us",
    component: <AboutUsTab />,
  },
  {
    value: "2",
    label: "practice_areas",
    path: "practice-areas",
    component: <PracticeAreasTab />,
  },

  {
    value: "3",
    label: "our_people",
    path: "our-people",
    component: <OurPeopleTab />,
  },
  {
    value: "4",
    label: "blogs",
    path: "blogs",
    component: <NewsInsightstab />,
  },

  {
    value: "5",
    label: "careers",
    path: "careers",
    component: <CareersTab />,
  },
];

const appBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  pl: { lg: "30px", sm: "2px" },
  pr: { lg: "30px", sm: "2px" },
};

const innerFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const innerCenterFlexBoxStyle = {
  display: "flex",
  alignItems: "center",
  //justifyContent: "space-between",
};

const appBarTextStyle = {
  fontSize: { lg: "16px", sm: "12px" },
  fontWeight: 500,
  color: "rgba(255, 255, 255, 1)",
  pl: "3px",
  pr: "3px",
};

const contactUsBoxStyle = {
  p: { lg: "38px", sm: "20px", md: "30px", xs: "30px" },
  background: "rgba(97, 204, 245, 1)",
  display: "flex",
  alignItems: "center",
  // mr: { sm: "15px", md: "0px" },
};

const contactUsTextStyle = {
  fontSize: { lg: "16px", sm: "15px" },
  fontWeight: { lg: 700, sm: 600 },
  color: "rgba(255, 255, 255, 1)",
  width: "100%",
};

const secontAppBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  //mt: { xs: "51.3px", lg: "0px", sm: "63.5px" },
  background: "#fff",
  //p: "18px",
  pl: "5px",
  pr: "5px",
};

const secontAppBarStylee = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  background: "#fff",
  // mt: { xs: "51.3px", lg: "0px", sm: "63.5px" },
};

const styleTabs = {
  display: "flex",
  // flexDirection: "column",
  bgcolor: "rgba(255, 255, 255, 0.9)",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  //width: "100%",
  //pt: "20px",

  "& button.Mui-selected": {
    color: "#61CCF5",
    fontSize: "16px",
    fontWeight: 550,
    lineHeight: "28px",
    minHeight: "40px",
  },

  "& .MuiTabs-flexContainer": {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },

  "& .MuiTabs-scroller": {
    display: "flex",
    // flexDirection: "column",
    alignItems: "center",
  },

  "& .MuiButtonBase-root:not(.Mui-selected)": {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "16px",
    fontWeight: 500,
    minHeight: "40px",
    lineHeight: "20px",
  },
};

const styleClickableTab = {
  borderRadius: "8px",
  minWidth: "134px",
  // width: "100%",
};

const styleTab = {
  minHeight: "35px",
  minWidth: "134px",
  // width: "100%",
};

const logoStyle = {
  width: { lg: "182px", sm: "190px", md: "182px", xs: "auto" },
  height: { lg: "100px", sm: "61px", md: "100px", xs: "auto" },
};

const iconSocialStyle = {
  width: { lg: "20px", sm: "16px", xs: "11.86px" },
  height: { lg: "20px", sm: "16px", xs: "11.86px" },
  flexShrink: 0,
};

//_______________________________________________________________

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

//______________________________________________________

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { officeInfo } = useSelector((state) => state.general);

  const location = useLocation();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { tab } = useSelector((state) => state.general);

  const [currentTab, setCurrentTab] = useState(tab);

  const handleTabChange = (event, newValue) => {
    // Navigate based on the new value

    switch (newValue) {
      case "0":
        navigate(`/home`);

        break;

      case "1":
        navigate(`/about-us`);

        break;

      case "2":
        navigate(`/practice-areas`);

        break;

      case "3":
        navigate(`/our-people`);

        break;

      case "4":
        navigate(`/blogs`);

        break;

      case "5":
        navigate(`/careers`);

        break;

      default:
        break;
    }
  };

  // Synchronize tempTab with currentTab

  const isMediumScreen = useResponsive("between", "md", "lg");

  const { themeLayout } = useSettingsContext();

  const { currentLang, onChangeLang } = useLocales();

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
  };

  const isNavHorizontal = themeLayout === "horizontal";

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  useEffect(() => {
    dispatch(getOfficeInfoService());
    dispatch(getPrivacyService());
    dispatch(getTermsService());
    dispatch(getCookieService());
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;

    let newTab;

    if (currentPath === "/home") {
      newTab = "0";
    } else if (currentPath === "/about-us") {
      newTab = "1";
    } else if (currentPath === "/practice_areas") {
      newTab = "2";
    } else if (currentPath === "/our-people") {
      newTab = "3";
    } else if (currentPath === "/blogs") {
      newTab = "4";
    } else if (currentPath === "/careers") {
      newTab = "5";
    }

    // Update the tab state if it has changed

    if (newTab !== undefined && newTab !== tab) {
      setCurrentTab(newTab);

      dispatch(setTab(newTab));
    }
  }, [location.pathname]);

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal}
      {!isDesktop && !isSmallScreen && (
        <>
          <img
            alt="flag icon"
            src={
              currentLang.value === "en"
                ? "/assets/icons/flag_en.svg"
                : "/assets/icons/flag_ar.svg"
            }
            style={{ width: "24px", height: "24px" }}
            onClick={() =>
              handleChangeLang(currentLang.value === "en" ? "ar" : "en")
            }
          />
        </>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent={"flex-end"}
      >
        {(isDesktop || isSmallScreen) && (
          <>
            <Box sx={appBarStyle}>
              <Box sx={innerCenterFlexBoxStyle}>
                {/** call */}
                <Box sx={{ ...innerFlexBoxStyle }}>
                  <Box sx={iconSocialStyle}>
                    <img src="/assets/icons/call.svg" alt="call icon" />
                  </Box>
                  <Box sx={{ pl: "5px" }}></Box>
                  {officeInfo && officeInfo.phone_number ? (
                    theme.direction === "rtl" ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ ...appBarTextStyle }}>
                          {`${officeInfo.phone_number.slice(1)}`}{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { lg: "16px", sm: "12px" },
                            fontWeight: 500,
                            color: "rgba(255, 255, 255, 1)",
                          }}
                        >
                          +
                        </Typography>
                      </Box>
                    ) : (
                      <Typography sx={{ ...appBarTextStyle }}>
                        {officeInfo.phone_number}
                      </Typography>
                    )
                  ) : (
                    <Typography sx={{ ...appBarTextStyle }}></Typography>
                  )}
                </Box>

                <Box sx={{ pl: "8px" }}></Box>

                {/**mail */}
                <a
                  href={`mailto:${officeInfo.email}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Box sx={{ ...innerFlexBoxStyle }}>
                    <Box sx={iconSocialStyle}>
                      <img
                        src="/assets/icons/mail.svg"
                        alt="mail icon"
                        style={{ flexShrink: 0 }}
                      />
                    </Box>

                    <Box sx={{ pl: "5px" }}></Box>

                    <Typography sx={appBarTextStyle}>
                      {officeInfo.email}
                    </Typography>
                  </Box>
                </a>
              </Box>
              <Box
                sx={{ ...innerFlexBoxStyle, width: { lg: "20%", sm: "30%" } }}
              >
                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/twitter.svg" alt="twitter icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/linkedin.svg" alt="linkedin icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/facebook.svg" alt="facebook icon" />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/assets/icons/instagram.svg"
                      alt="instagram icon"
                    />
                  </a>
                </Box>

                <Box sx={iconSocialStyle}>
                  <a
                    href={officeInfo.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/assets/icons/watsup.svg" alt="whatsup icon" />
                  </a>
                </Box>

                <Box sx={{ pl: "5px", pr: "5px" }}></Box>
                <img
                  alt="flag icon"
                  src={
                    currentLang.value === "en"
                      ? "/assets/icons/flag_en.svg"
                      : "/assets/icons/flag_ar.svg"
                  }
                  style={{ width: "24px", height: "24px" }}
                  onClick={() =>
                    handleChangeLang(currentLang.value === "en" ? "ar" : "en")
                  }
                />
              </Box>
            </Box>
          </>
        )}
      </Stack>
    </>
  );

  const handleLogoClick = () => {
    navigate("/home");

    dispatch(setTab("0"));
  };

  const handleContactUsClick = () => {
    if (location.pathname !== "/home") {
      // Navigate to the Home tab

      navigate("/home");

      dispatch(setTab("0"));

      // Use a timeout to allow the navigation to complete before scrolling

      setTimeout(() => {
        // Scroll to the contactUs section

        const contactUsElement = document.getElementById("contactUs");

        if (contactUsElement) {
          contactUsElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the timeout duration as needed
    } else {
      // If already on the Home tab, just scroll to the contactUs section

      const contactUsElement = document.getElementById("contactUs");

      if (contactUsElement) {
        contactUsElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCall = () => {
    window.location.href = `tel:${officeInfo.phone_number}`;

    handleClose();
  };

  const handleMessage = () => {
    window.location.href = `sms:${officeInfo.phone_number}`;
    handleClose();
  };

  return (
    <>
      <AppBar
        sx={{
          position: "realtive",
        }}
      >
        <Toolbar
          sx={
            {
              //height: 1,
              // px: { xs: 5 },
            }
          }
        >
          {renderContent}
        </Toolbar>
        {(isDesktop || isSmallScreen) && (
          <Box sx={secontAppBarStylee}>
            {(isDesktop || isSmallScreen || isMediumScreen) && (
              <>
                <Box
                  onClick={handleLogoClick}
                  sx={{
                    ...logoStyle,
                    ...(theme.direction === "ltr"
                      ? { mr: "0px", ml: "15px" }
                      : { ml: "15px", mr: "0px" }),
                  }}
                >
                  <img src="/assets/icons/logo.svg" alt="logo icon" />
                </Box>
                <Tabs
                  value={tab || false}
                  onChange={(event, newValue) => {
                    //   setCurrentTab(() => {
                    //     console.log("hiiiiiiii");
                    //     return newValue; // Ensures the state updates immediately after navigation
                    //   });

                    setCurrentTab(newValue);
                    dispatch(setTab(newValue));

                    if (newValue === "0") {
                      navigate(`/home`);
                    } else if (newValue === "1") {
                      // Contact Us tab
                      navigate(`/about-us`);
                    } else if (newValue === "2") {
                      navigate(`/practice-areas`);
                    } else if (newValue === "3") {
                      navigate(`our-people`);
                    } else if (newValue === "4") {
                      navigate(`blogs`);
                    } else if (newValue === "5") {
                      navigate(`careers`);
                    }
                  }}
                  //  onChange={handleChange}
                  sx={styleTabs}
                  TabIndicatorProps={{
                    style: { display: "none" },
                  }}
                >
                  {TABS.map((tab) => (
                    <Tab
                      sx={
                        tab.path === tab
                          ? {
                              styleClickableTab,
                            }
                          : { styleTab }
                      }
                      key={tab.value}
                      value={tab.value}
                      label={translate(tab.label)}
                    />
                  ))}
                </Tabs>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="contactUs" // This should match the name in the Element in HomeTab
                    smooth={true}
                    duration={500}
                    onClick={handleContactUsClick} // Use the click handler
                    style={{ cursor: "pointer", textDecoration: "none" }} // Add cursor pointer for better UX
                    href={"/home"}
                  >
                    <Box
                      sx={{
                        ...contactUsBoxStyle,
                        cursor: "pointer",
                        ...(theme.direction === "ltr"
                          ? { mr: "0px", ml: "15px" }
                          : { ml: "15px", mr: "0px" }),
                      }}
                    >
                      <Typography sx={contactUsTextStyle}>
                        {translate("consult_us")}
                      </Typography>
                    </Box>
                  </Link>
                </Box>
              </>
            )}
          </Box>
        )}

        {!isDesktop && !isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              //  pl: "10px",
              // pr: "20px",
              //  ...(theme.direction === "rtl" ? { pr: "0px" } : { pl: "10px" }),
            }}
            component="nav"
          >
            <Box sx={secontAppBarStyle}>
              <img
                onClick={onOpenNav}
                alt="more icon"
                src="/assets/icons/icon.svg"
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box onClick={handleLogoClick} sx={logoStyle}>
                  <img src="/assets/icons/logo.svg" alt="logo icon" />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </AppBar>
    </>
  );
}
