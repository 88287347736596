import PropTypes from "prop-types";

import { forwardRef } from "react";

import { Link as RouterLink } from "react-router-dom";

import { Box, Link } from "@mui/material";

import { styled } from "@mui/material/styles";

// Styled component for the logo with hover effect

const StyledLogo = styled(Box)(({ theme }) => ({
  width: 120,

  height: 120,

  //display: "inline-flex",

  alignItems: "center",

  justifyContent: "center",

  //borderRadius: "50%", // Make it circular

  //background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`, // Gradient background

  transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",

  //boxShadow: `0 4px 20px rgba(0, 0, 0, 0.2)`, // Initial shadow

  "&:hover": {
    transform: "scale(1.1) rotate(10deg)", // Slightly enlarge and rotate on hover

    boxShadow: `0 8px 30px rgba(0, 0, 0, 0.3)`, // Enhanced shadow on hover

    background: `linear-gradient(135deg, ${theme.palette.secondary.main}, ${theme.palette.primary.main})`, // Reverse gradient on hover
  },

  // Pulse effect

  animation: "pulse 2s infinite",

  "@keyframes pulse": {
    "0%": { transform: "scale(1)" },

    "50%": { transform: "scale(1.05)" },

    "100%": { transform: "scale(1)" },
  },
}));

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <StyledLogo ref={ref} {...other} sx={sx}>
      <img src="/assets/icons/logo.svg" alt="logo" />
    </StyledLogo>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,

  disabledLink: PropTypes.bool,
};

export default Logo;
