import { m } from "framer-motion";
// @mui
import { alpha, styled } from "@mui/material/styles";
import { Box } from "@mui/material";
//
import Logo from "../logo";
import ProgressBar from "../progress-bar";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 9998,
  width: "100%",
  height: "100%",
  position: "fixed",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen() {
  return (
    <>
      <ProgressBar />

      <StyledRoot>
        <m.div
          animate={{
            scale: [1, 0.9, 0.9, 1, 1],
            opacity: [1, 0.48, 0.48, 1, 1],
          }}
          transition={{
            duration: 2,
            ease: "easeInOut",
            repeatDelay: 1,
            repeat: Infinity,
          }}
        >
          <Logo disabledLink sx={{ width: 100, height: 100 }} />
        </m.div>
      </StyledRoot>
    </>
  );
}
