import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga'

export const initGA = (trackingID) => {
    ReactGA.initialize(trackingID);
};

export const logPageView = () => {
    ReactGA.set({page: window.location.pathname});
    ReactGA.pageview(window.location.pathname);
};

export const useAnalytics = () => {
    const location = useLocation();
    useEffect(() => {
        logPageView();
    }, [location]);
};