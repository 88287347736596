import React from "react";

// mui
import { Box, Typography, Grid } from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import useResponsive from "../../hooks/useResponsive";

import { successToast } from "../../utils/toastMessage";

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import FormProvider, { RHFTextField } from "../../components/hook-form";

import { LoadingButton } from "@mui/lab";

import { createContactUsService } from "../../redux/services/general_service";

import { Element } from "react-scroll";
//____________________________________________________

//---------- Styles -----------

const txtFieldBorderStyle = {
  borderRadius: "0px",

  zIndex: 100,

  "& .MuiFormLabel-root": {
    //  color: "#FFFFFF", // Change label color to white

    zIndex: 10,

    fontSize: "16px",
  },

  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    opacity: 0.7,
    borderRadius: "0px",

    height: "52px",

    border: "2px solid #61CCF5", // Add stroke color as border
    zIndex: 10,

    "& fieldset": {
      border: "none",
    },

    "&:hover fieldset": {
      border: "none",
    },

    "&.Mui-focused fieldset": {
      border: "none",
    },
  },

  "& input": {
    color: "#1E184CF0", // Set input text color to white

    zIndex: 100,

    "&::placeholder": {
      // color: "#FFFFFF", // Set placeholder color to white

      opacity: 1, // Ensure the placeholder is fully opaque

      zIndex: 100,
    },

    // Optional: Change text color when focused

    "&:focus": {
      color: "#1E184CF0", // Ensure text color remains white when focused
      zIndex: 10,
      //opacity: 100,
    },
  },
};

const multiTxtFieldBorderStyle = {
  borderRadius: "0px",

  zIndex: 100,

  "& .MuiFormLabel-root": {
    //  color: "#FFFFFF", // Change label color to white

    zIndex: 10,

    fontSize: "16px",
  },

  "& .MuiOutlinedInput-root": {
    background: "#F5F5F5",
    opacity: 0.7,
    borderRadius: "0px",

    // height: "52px",

    border: "2px solid #61CCF5", // Add stroke color as border
    zIndex: 10,

    "& fieldset": {
      border: "none",
    },

    "&:hover fieldset": {
      border: "none",
    },

    "&.Mui-focused fieldset": {
      border: "none",
    },
  },

  "& input": {
    color: "#1E184CF0", // Set input text color to white

    zIndex: 100,

    "&::placeholder": {
      // color: "#FFFFFF", // Set placeholder color to white

      opacity: 1, // Ensure the placeholder is fully opaque

      zIndex: 100,
    },

    // Optional: Change text color when focused

    "&:focus": {
      color: "#1E184CF0", // Ensure text color remains white when focused
      zIndex: 10,
      //opacity: 100,
    },
  },
};

const topSaveButtonStyle = {
  fontWeight: 700,
  zIndex: 10,
  fontSize: "16px",
  lineHeight: "20px",
  color: "#FFFFFF",
  bgcolor: "#61CCF5",
  borderRadius: "0px",

  // width: { lg: "170px", sm: "142px", xs: "142px" },
  mx: { xs: "auto", sm: "0" },
  mb: "40px",
  height: { lg: "48px", sm: "40px", xs: "40px" },
  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const formBoxStyle = {
  backgroundImage: "url('/assets/images/ph.png')",
  backgroundSize: "cover",
  backgroundPosition: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  //height: "529px",
  width: "100%",
  position: "relative", // Ensure the box is positioned relative
  pb: { xs: "20px" },
  pt: { xs: "20px" },
};

const overlayFormStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "#1E184CF0",
  zIndex: 1,
};

const blurTitleStyle = {
  fontSize: { lg: "32px", md: "32px", xs: "16px", sm: "32px" },
  fontWeight: 700,
  color: "#FFFFFF",
  mb: "10px",
  zIndex: 100,
  lineHeight: "42px",
};

const titleStyle = {
  fontSize: { lg: "32px", md: "32px", xs: "24px", sm: "32px" },
  fontWeight: 700,
  color: "#61CCF5",
  mb: "10px",
  zIndex: 100,
  lineHeight: "24px",
};

const contentColunmBox = {
  display: "flex",
  flexDirection: "column",
};

const lineStyle = {
  width: "66px",
  zIndex: 10,
  display: "flex",
  // justifyContent: "end",
  mb: "30px",
};

const additionstext = {
  fontSize: { lg: "16px", md: "16px", xs: "12px", sm: "12px" },
  fontWeight: 700,
  color: "#FBFBFB",
  //mb: "15px",
  zIndex: 100,

  //textAlign: "end",
};

const iconCallStyle = {
  width: { lg: "24px", sm: "14.23px", xs: "14.23px" },
  height: { lg: "24px", sm: "14.23px", xs: "14.23px" },
  flexShrink: 0,
};

const flexBoxColumn = {
  display: "flex",
  //alignItems: "center",
  flexDirection: "column",
  margin: "auto",
};

const flexBoxStart = {
  display: "flex",
  mb: "15px",
};

//_______________________________________________________
export default function CountactUs() {
  const dispatch = useDispatch();

  const { officeInfo, isLoadingContactUs } = useSelector(
    (state) => state.general
  );

  const { translate } = useLocales();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const FormSchema = Yup.object().shape({});

  const defaultValues = {};

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });
  const { handleSubmit, setValue } = methods;

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("email", data?.email);
    formData.append("content", data?.content);

    dispatch(createContactUsService(formData)).then((response) => {
      if (response?.payload?.status === 200) {
        successToast("تمت عملية الإضافة بنجاح");
      } else {
        response?.payload?.errors?.map((error, index) => toast.warn(error));
      }
    });
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={formBoxStyle}>
          <Box sx={overlayFormStyle}></Box>

          <Grid
            container
            columnSpacing={{ lg: 5, sm: 2 }}
            zIndex={10}
            pl={{ lg: "100px", md: "30px", sm: "20px", xs: "10px" }}
            pr={{ lg: "100px", md: "30px", sm: "20px", xs: "10px" }}
            pt={{ lg: "60px" }}
          >
            <Grid item xs={12} sm={6} md={5}>
              <Box sx={contentColunmBox}>
                <Typography
                  sx={{
                    ...titleStyle,
                    fontSize: {
                      lg: "32px",
                      md: "32px",
                      xs: "24px",
                      sm: "24px",
                    },
                  }}
                >
                  {translate("contact_us")}
                </Typography>

                <Box sx={{ ...lineStyle, mb: "10px" }}>
                  <img alt="Line icon" src="/assets/icons/Line 1.svg" />
                </Box>
                <Typography sx={blurTitleStyle}>{translate("feel")}</Typography>
              </Box>
            </Grid>
            {(isDesktop || isMediumScreen) && (
              <Grid item xs={12} sm={12} md={2}></Grid>
            )}

            <Grid item xs={12} sm={6} md={5}>
              <Box sx={{ ...flexBoxColumn, mt: "20px" }}>
                <Box sx={{ ...flexBoxStart }}>
                  <Box sx={iconCallStyle}>
                    <img
                      src="/assets/icons/location.svg"
                      alt="location.svg image"
                      style={{
                        marginTop: "2px",
                      }}
                    />
                  </Box>
                  <Box sx={{ pl: "5px", pr: "5px" }}> </Box>
                  <Typography
                    sx={{
                      ...additionstext,
                      textAlign: "justify",
                      lineHeight: "24px",
                    }}
                  >
                    {theme.direction === "ltr"
                      ? officeInfo.address_en
                      : officeInfo.address_ar}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: { lg: "center" },
                    justifyContent: "space-between",
                    flexDirection: { lg: "row", sm: "row", xs: "column" },
                    width: { lg: "100%", sm: "80%", xs: "100%" },
                  }}
                >
                  <Box
                    sx={{
                      ...flexBoxStart,
                      alignItems: "center",
                    }}
                  >
                    <Box sx={iconCallStyle}>
                      <img src="/assets/icons/call.svg" alt="call icon" />
                    </Box>
                    <Box sx={{ pl: "2px", pr: "2px" }}> </Box>
                    <Typography sx={{ ...additionstext, lineHeight: "30px" }}>
                      {officeInfo.phone_number}
                    </Typography>
                  </Box>

                  <Box sx={{ ...flexBoxStart, alignItems: "center" }}>
                    <Box sx={iconCallStyle}>
                      <img src="/assets/icons/mail.svg" alt="mail icon" />
                    </Box>
                    <Box sx={{ pl: "2px", pr: "2px" }}> </Box>
                    <Typography sx={{ ...additionstext, lineHeight: "30px" }}>
                      {officeInfo.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={{ lg: 2, sm: 2 }}
            pl={{ lg: "100px", md: "30px", sm: "20px", xs: "10px" }}
            pr={{ lg: "100px", md: "30px", sm: "20px", xs: "10px" }}
          >
            <Grid item xs={12} sm={4} mb={{ xs: "10px" }}>
              <RHFTextField
                //variant="outlined"
                label={translate("first_name")}
                name="first_name"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={4} mb={{ xs: "10px" }}>
              <RHFTextField
                label={translate("last_name")}
                name="last_name"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={4} mb={{ xs: "10px" }}>
              <RHFTextField
                label={translate("email")}
                name="email"
                type="text"
                sx={txtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt="30px">
              <RHFTextField
                multiline
                label={translate("write")}
                rows={4}
                name="content"
                type="text"
                sx={multiTxtFieldBorderStyle}
              />
            </Grid>

            <Grid item xs={12} sm={12} mt={"30px"}>
              <LoadingButton
                fullWidth
                size="large"
                variant="Contained"
                sx={topSaveButtonStyle}
                type="submit"
                loading={isLoadingContactUs}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
}
