// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ar = {
  home_page: `الصفحة الرئيسية`,
  consult_us: `استشرنا`,
  home: `الصفحة الرئيسية`,
  about_us: `معلومات عنا`,
  practice_areas: `مجالات العمل`,
  our_people: `فريقنا`,
  blogs: `مدونات`,
  careers: `الوظائف`,
  over: `فوق`,
  three_decades: `ثلاثة عقود`,
  of_legal_expertise: `الخبرة القانونية`,
  offering: `تقديم خدمات قانونية مصممة خصيصًا في الكويت وعلى المستوى الدولي،متخصصون في قانون الشركات، والتقاضي، والخدمات المصرفية، وأكثر من ذلك`,
  explore_more: ` اكتشف المزيد`,
  you_have_seen_it_all: `لقد رأيت كل شيء!`,
  key_practice_areas: `مجالات العمل الرئيسية`,
  key_practice_content: `يقدم مكتب العدواني للمحاماة خدمات قانونية شاملة في قانون الشركات، والتقاضي، والتحكيم، والملكية الفكرية، والمزيد، ويقدم حلولاً مصممة خصيصًا لتلبية أهدافك وطموحاتك`,
  testimonials: `شهادات`,
  our_happy_clients: `عملائنا السعداء`,
  quick_Links: `روابط سريعة`,
  contact_us: `اتصل بنا`,
  job: `التقدم للحصول على وظيفة`,
  name: `الاسم الكامل`,
  email: `الايميل`,
  address: `العنوان`,
  phone: `رقم الهاتف`,
  educational_degree: `الدرجة التعليمية`,
  applied: `الموقف التطبيقي`,
  cv: `السيرة الذاتية / تحميل السيرة الذاتية`,
  submit: `تقديم`,
  first_name: `الاسم الاول`,
  last_name: `الكنية`,
  feel: `لا تتردد في طرح أي شيء هنا`,
  write: `اكتب`,
  cookie: `سياسة ملفات تعريف الارتباط`,
  terms: `الشروط والأحكام`,
  privacy: `سياسة الخصوصية`,
  write_about: `اكتب عن نفسك`,
  company_title: `شركة العدواني للمحاماة`,
  adwani: `شركة العدواني`,
  details: `تفاصيل`,
  cancel: `اغلاق`,
  message: `رسالة`,
  call: `اتصال`,
  how: `كيف تريد التواصل؟`,
  options: `خيارات الاتصال`,
};

export default ar;
