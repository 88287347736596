import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// slices
import generalReducer from "./slices/general";
import seoReducer from './slices/seoSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const generalPersistConfig = {
  key: "general",
  storage,
  keyPrefix: "redux-",
  whitelist: ["tab", "cardId", "headerTitle", "headerTitleAr"],
};

const rootReducer = combineReducers({
  general: persistReducer(generalPersistConfig, generalReducer),
  seo: seoReducer,
});

export { rootPersistConfig, rootReducer };
