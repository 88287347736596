const baseTitle = "Al Adwani";
const arBaseTitle = "شركة العدواني";
const separator = " - ";

const moreOpenGraphMetaData = [

    {property: "og:image", content: "https://aladwani.sourcemediakw.com/assets/icons/logo.svg"},
    {property: "og:url", content: "https://aladwani.sourcemediakw.com/home"},
    {property: "og:type", content: "website"},

    {property: "og:site_name", content: "Al Adwani Law Firm"},
    {property: "og:locale", content: "en_US"},

    {name: "twitter:title", content: "AlAdwani law firm"},
    {
        name: "twitter:description",
        content: "Al-Adwani Law Firm is an international legal firm uniquely positioned to help our clients achieve their ambitions in today's world of globalization."
    },
    {name: "twitter:url", content: "https://x.com/AlAdwaniLawFirm"},
];

const moreArOpenGraphMetaData = [

    {property: "og:image", content: "https://aladwani.sourcemediakw.com/assets/icons/logo.svg"},
    {property: "og:url", content: "https://aladwani.sourcemediakw.com/home"},
    {property: "og:type", content: "website"},

    {property: "og:site_name", content: "شركة العدواني للمحاماة"},
    {property: "og:locale", content: "en_US"},

    {name: "twitter:title", content: "شركة العدواني للمحاماة"},
    {
        name: "twitter:description",
        content: "يعد مكتب العدواني للمحاماة مكتباً قانونياً دولياً يتمتع بموقع فريد لمساعدة عملائنا على تحقيق طموحاتهم في عالم اليوم الذي يتسم بالعولمة."
    },
    {name: "twitter:url", content: "https://x.com/AlAdwaniLawFirm"},
];

export const homeMetaData = {
    en: {
        title: baseTitle + " Law Firm",
        description: "Al Adwani Law Firm, a premier Law firm dedicated to delivering unparalleled legal services to our esteemed clients",
        keywords: "law office, Law Firm, legal services, attorney, lawyer, legal assistance, expert legal advice, professional law firm",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Expert Legal Services for All Your Needs"
            },
            {
                property: "og:description",
                content: "Al Adwani Law Firm, a premier Law firm dedicated to delivering unparalleled legal services to our esteemed clients"
            },
            ...moreOpenGraphMetaData
        ]
    },
    ar: {
        title: arBaseTitle + " للمحاماة",
        description: "شركة العدواني للمحاماة، مكتب محاماة رائد مخصص لتقديم خدمات قانونية لا مثيل لها لعملائنا الكرام",
        keywords: "مكتب محاماة, خدمات قانونية, محامي, محاماة, مساعدة قانونية, نصيحة قانونية, مكتب محاماة مهني",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - خدمات قانونية احترافية لكل احتياجاتك"
            },
            {
                property: "og:description",
                content: "شركة العدواني للمحاماة، مكتب محاماة رائد مخصص لتقديم خدمات قانونية لا مثيل لها لعملائنا الكرام"
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const aboutUsMetaData = {
    en: {
        title: baseTitle + separator + "About Us",
        description: "Learn more about Al Adwani Law Firm, our mission, our team, and our commitment to excellence in legal services.",
        keywords: "about us, About Al Adwani, law firm, legal team, legal services, professional law firm",
        additionalMetaTags: [{
            property: "og:title",
            content: "About Us - Al Adwani Law Firm"
        }, {
            property: "og:description",
            content: "Learn more about Al Adwani Law Firm, our mission, our team, and our commitment to excellence in legal services"
        }, ...moreOpenGraphMetaData]
    },
    ar: {
        title: arBaseTitle + separator + " من نحن",
        description: "تعرف على المزيد حول شركة محاماة العدواني ومهمتنا وفريقنا والتزامنا بالتميز في الخدمات القانونية.",
        keywords: "من نحن, عن العدواني, شركة محاماة, فريق قانوني, خدمات قانونية, شركة محاماة محترفة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "من نحن - شركة محاماة العدواني"
            },
            {
                property: "og:description",
                content: "تعرف على المزيد حول شركة محاماة العدواني ومهمتنا وفريقنا والتزامنا بالتميز في الخدمات القانونية"
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const blogsMetaData = {
    en: {
        title: baseTitle + separator + "Blogs",
        description: "Stay informed with our latest blog posts covering various legal topics, updates, and expert insights from our experienced attorneys.",
        keywords: "blogs, legal blogs, legal insights, legal updates, law blog",
        additionalMetaTags: [{
            property: "og:title",
            content: "Blogs - Legal Insights and Updates"
        }, {
            property: "og:description",
            content: "Stay informed with our latest blog posts covering various legal topics, updates, and expert insights from our experienced attorneys"
        }, ...moreOpenGraphMetaData]
    },
    ar: {
        title: arBaseTitle + separator + " المدونات",
        description: "ابقَ على اطلاع من خلال آخر مدوناتنا التي تغطي مواضيع قانونية متنوعة ومستجدات ورؤى الخبراء من محامينا ذوي الخبرة.",
        keywords: "مدونات, مدونات قانونية, رؤى قانونية, مستجدات قانونية, مدونة قانون",
        additionalMetaTags: [{
            property: "og:title",
            content: "المدونات - رؤى قانونية ومستجدات"
        }, {
            property: "og:description",
            content: "ابقَ على اطلاع من خلال آخر مدوناتنا التي تغطي مواضيع قانونية متنوعة ومستجدات ورؤى الخبراء من محامينا ذوي الخبرة"
        }, ...moreArOpenGraphMetaData]
    }
};

export const peopleMetaData = {
    en: {
        title: baseTitle + separator + "Our People",
        description: "Get to know our team of skilled attorneys and legal professionals dedicated to providing exceptional legal services and personalized client care.",
        keywords: "our people, legal team, attorneys, legal professionals, law office staff",
        additionalMetaTags: [{
            property: "og:title",
            content: "Our People - Meet Our Team of Legal Experts"
        }, {
            property: "og:description",
            content: "Get to know our team of skilled attorneys and legal professionals dedicated to providing exceptional legal services and personalized client care."
        }, ...moreOpenGraphMetaData]
    },
    ar: {
        title: arBaseTitle + separator + "فريقنا",
        description: "تعرف على فريق المحامين المحترفين لدينا والمتخصصين في تقديم خدمات قانونية استثنائية ورعاية عملاء شخصية.",
        keywords: "فريقنا, فريق قانوني, محامين, مهنيين قانونيين, موظفي مكتب المحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "فريقنا - التعرف على فريق الخبراء القانونيين"
            },
            {
                property: "og:description",
                content: "تعرف على فريق المحامين المحترفين لدينا والمتخصصين في تقديم خدمات قانونية استثنائية ورعاية عملاء شخصية."
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const careersMetaData = {
    en: {
        title: baseTitle + separator + "Careers",
        description: "Explore career opportunities at our law office and become part of a team that values excellence, integrity, and professional growth.",
        keywords: "careers, law office jobs, legal careers, legal employment",
        additionalMetaTags: [{
            property: "og:title",
            content: "Careers - Join Our Al Adwani Team"
        }, {
            property: "og:description",
            content: "Explore career opportunities at our law office and become part of a team that values excellence, integrity, and professional growth."
        }, ...moreOpenGraphMetaData]
    },
    ar: {
        title: arBaseTitle + separator + "المسارات المهنية",
        description: "استكشف فرص العمل في مكتبنا القانوني وكن جزءًا من فريق يقدر التميز والنزاهة والنمو المهني.",
        keywords: "مسارات مهنية, وظائف مكتب المحاماة, وظائف قانونية, التوظيف القانوني",
        additionalMetaTags: [{
            property: "og:title",
            content: "المسارات المهنية - انضم إلى فريق شركة العدواني"
        }, {
            property: "og:description",
            content: "استكشف فرص العمل في مكتبنا القانوني وكن جزءًا من فريق يقدر التميز والنزاهة والنمو المهني."
        }, ...moreArOpenGraphMetaData]
    }
};

export const practiceAreaMetaData = {
    en: {
        title: baseTitle + separator + "Practice Areas",
        description: "Discover our wide range of practice areas, Al Adwani is Offering tailored legal services in Kuwait and internationally, specializing in corporate law, litigation, banking, and more",
        keywords: "Legal Services, Expert Lawyers, Kuwait Law Firm, Corporate Law, Banking Law, International Legal Services, Specialized Legal Practice, Practice Areas, Comprehensive Legal Services, Tailored Legal Solutions",
        additionalMetaTags: [{
            property: "og:title",
            content: "Practice Areas - Comprehensive Legal Services"
        }, {
            property: "og:description",
            content: "Discover our wide range of practice areas, Al Adwani is specializing in corporate law, litigation, banking, and more"
        }, ...moreOpenGraphMetaData]
    },
    ar: {
        title: arBaseTitle + separator + "مجالات العمل",
        description: "اكتشف نطاقنا الواسع من مجالات الممارسة، شركة العدواني تقدم خدمات قانونية مخصصة في الكويت وعلى المستوى الدولي، متخصصة في القانون التجاري، التقاضي، البنوك، وأكثر",
        keywords: "خدمات قانونية، محامون خبراء، شركة محاماة الكويت، القانون التجاري، قانون البنوك، خدمات قانونية دولية، ممارسة قانونية متخصص، مجالات الممارسة، خدمات قانونية شاملة، حلول قانونية مخصصة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "مجالات الممارسة - خدمات قانونية شاملة"
            },
            {
                property: "og:description",
                content: "اكتشف نطاقنا الواسع من مجالات الممارسة، شركة العدواني متخصصة في القانون التجاري، التقاضي، البنوك، وأكثر"
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const privacyPolicyMetaData = {
    en: {
        title: "privacy Policy",
        description: "Read Al Adwani Law Firm's privacy policy to learn how we protect your personal information and ensure your privacy.",
        keywords: "privacy policy, data protection, information security, law firm privacy, legal privacy policy, Al Adwani Law Firm",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Privacy Policy"
            },
            {
                property: "og:description",
                content: "Read Al Adwani Law Firm's privacy policy to learn how we protect your personal information and ensure your privacy."
            },
            ...moreOpenGraphMetaData
        ]
    },
    ar: {
        title: "سياسة الخصوصية",
        description: "اقرأ سياسة الخصوصية لشركة العدواني للمحاماة لتتعرف على كيفية حماية معلوماتك الشخصية وضمان خصوصيتك.",
        keywords: "سياسة الخصوصية، حماية البيانات، أمن المعلومات، خصوصية شركة المحاماة، سياسة الخصوصية القانونية، شركة العدواني للمحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - سياسة الخصوصية"
            },
            {
                property: "og:description",
                content: "اقرأ سياسة الخصوصية لشركة العدواني للمحاماة لتتعرف على كيفية حماية معلوماتك الشخصية وضمان خصوصيتك."
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const termsConditionsMetaData = {
    en: {
        title: "Terms ِAnd Conditions",
        description: "Review the terms and conditions of Al Adwani Law Firm to understand the rules and regulations governing the use of our services.",
        keywords: "terms and conditions, legal terms, law firm terms, Al Adwani Law Firm, service terms, legal agreement",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Terms & Conditions"
            },
            {
                property: "og:description",
                content: "Review the terms and conditions of Al Adwani Law Firm to understand the rules and regulations governing the use of our services."
            },
            ...moreOpenGraphMetaData
        ]
    },
    ar: {
        title: "الشروط والأحكام",
        description: "راجع الشروط والأحكام الخاصة بشركة العدواني للمحاماة لفهم القواعد واللوائح التي تحكم استخدام خدماتنا.",
        keywords: "الشروط والأحكام, الشروط القانونية, شروط شركة المحاماة, شركة العدواني للمحاماة, شروط الخدمة, الاتفاقية القانونية",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - الشروط والأحكام"
            },
            {
                property: "og:description",
                content: "راجع الشروط والأحكام الخاصة بشركة العدواني للمحاماة لفهم القواعد واللوائح التي تحكم استخدام خدماتنا."
            },
            ...moreArOpenGraphMetaData
        ]
    }
};

export const cookiesMetaData = {
    en: {
        title: "Cookie Policy",
        description: "Learn about how Al Adwani Law Firm uses cookies to enhance your browsing experience and ensure the functionality of our website.",
        keywords: "cookie policy, cookies, data tracking, website cookies, law firm cookies, Al Adwani Law Firm",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "Al Adwani Law Firm - Cookie Policy"
            },
            {
                property: "og:description",
                content: "Learn about how Al Adwani Law Firm uses cookies to enhance your browsing experience and ensure the functionality of our website."
            },
            ...moreOpenGraphMetaData
        ]
    },
    ar: {
        title: "سياسة ملفات تعريف الارتباط",
        description: "تعرف على كيفية استخدام شركة العدواني للمحاماة للكوكيز لتحسين تجربة التصفح وضمان فعالية موقعنا الإلكتروني.",
        keywords: "سياسة الكوكيز، الكوكيز، تتبع البيانات، كوكيز الموقع، كوكيز شركة المحاماة، شركة العدواني للمحاماة",
        additionalMetaTags: [
            {
                property: "og:title",
                content: "شركة العدواني للمحاماة - سياسة الكوكيز"
            },
            {
                property: "og:description",
                content: "تعرف على كيفية استخدام شركة العدواني للمحاماة للكوكيز لتحسين تجربة التصفح وضمان فعالية موقعنا الإلكتروني."
            },
            ...moreArOpenGraphMetaData
        ]
    }
};
