import React, { useEffect } from "react";

// mui
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

import { useLocales } from "../../locales";

import { useTheme } from "@mui/material/styles";

import { useDispatch, useSelector } from "react-redux";

import { getPracticeTopicService } from "../../redux/services/general_service";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

import "swiper/css";

import "swiper/css/navigation";

import "swiper/css/pagination";

import useResponsive from "../../hooks/useResponsive";

import { setTab } from "../../redux/slices/general";

//____________________________________________________

//---------- Styles -----------

const secondGrid = {
  background: "rgba(245, 245, 245, 1)",
  pl: { xs: "30px", sm: "0px" },
  pr: { xs: "30px", sm: "0px" },
};

const titleStyle = {
  fontSize: { lg: "32px", sm: "24px", xs: "24px", md: "32px" },
  lineHeight: { lg: "24px", sm: "24px", xs: "24px", md: "24px" },
  fontWeight: 700,
  color: "rgba(30, 24, 76, 1)",
  textAlign: "center",
  mb: "15px",
  mt: "40px",
};

const textStyle = {
  fontSize: { lg: "20px", sm: "16px", xs: "16px", md: "20px" },
  lineHeight: { lg: "28px", sm: "28px", xs: "28px", md: "28px" },
  fontWeight: 500,
  color: "rgba(30, 24, 76, 1)",
  mb: { xs: "30px", sm: "30px", lg: "40px" },
  textAlign: "justify",
};

const boxTitleStyle = {
  display: "flex",
  alignItems: "Center",
  flexDirection: "column",
  mb: { xs: "20px", sm: "10px", lg: "30px" },
  zIndex: 10,
};

const blueTitle = {
  fontSize: { lg: "20px", md: "20px", sm: "13px", xs: "16px" },
  lineHeight: { lg: "24px", md: "24px", sm: "10px", xs: "20px" },
  fontWeight: 700,
  color: "rgba(97, 204, 245, 1)",
  mb: "10px",
  textAlign: "center",
};

const blackTitle = {
  fontSize: { lg: "14px", md: "14px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "24px", md: "24px", sm: "18px", xs: "18px" },
  fontWeight: 500,
  color: "rgba(0, 0, 0, 1)",
  textAlign: "justify",
};

const cardImgStyle = {
  // width: "100%",
  height: { lg: "273px", sm: "173px", xs: "166px", md: "273px" },
};

const buttonAiStyle = {
  color: "#1E184C",
  fontWeight: 400,
  fontSize: { lg: "16px", sm: "12px", xs: "12px" },
  lineHeight: { lg: "20px", sm: "15px", xs: "15px" },
  bgcolor: "rgba(97, 204, 245, 1)",
  height: { lg: "48px", sm: "40px", xs: "40px" },
  width: { lg: "170px", sm: "142px", xs: "142px" },
  borderRadius: "0px",
  zIndex: 10,
  mt: { lg: "20px", xs: "0px", sm: "5px" },
  mb: { xs: "20px", sm: "0px" },

  "&:hover": {
    bgcolor: "text.primary",
    color: (theme) =>
      theme.palette.mode === "light" ? "common.white" : "grey.800",
  },
  "&:focus": {
    bgcolor: "#6A8490",
    color: "#FFFFFF",
  },
};

const bigColumnFlexBox = {
  display: "flex",
  borderRadius: 0,
  margin: "auto",
  pb: { xs: "66px", sm: "0px" },
  width: { lg: "364px", xs: "214px", sm: "220px" },
  mb: "40px",
  position: "relative",
};

const cardContentSx = {
  my: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
  scrollbarWidth: "none",
  height: { lg: "110px", sm: "80px", xs: "auto", md: "100px" },
  background: "rgba(255, 255, 255, 1)",
};
//_______________________________________________________
export default function PracticeAreas() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const baseUrl = "https://backend.aladwani.sourcemediakw.com";

  const { translate } = useLocales();

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");

  const isSmallScreen = useResponsive("up", "sm");

  const isMediumScreen = useResponsive("between", "md", "lg");

  const { practiceTitle, practiceTopicList } = useSelector(
    (state) => state.general
  );

  useEffect(() => {
    dispatch(
      getPracticeTopicService({
        page: 1,
        page_size: 10,
      })
    );
  }, [dispatch]);

  return (
    <>
      {(isDesktop || isSmallScreen || isMediumScreen) && (
        <Box sx={secondGrid}>
          <Box sx={boxTitleStyle}>
            <Typography sx={titleStyle}>
              {translate("key_practice_areas")}
            </Typography>

            <img alt="Line icon" src="/assets/icons/Line 1.svg" />
          </Box>

          <Box
            sx={{ pl: { lg: "20%", sm: "4%" }, pr: { lg: "20%", sm: "4%" } }}
          >
            <Typography sx={{ ...textStyle }}>
              {theme.direction === "ltr"
                ? practiceTitle.details_en
                : practiceTitle.details_ar}
            </Typography>
          </Box>

          {/* Custom Navigation Buttons */}
          <div style={{ position: "relative", width: "100%" }}>
            <div
              className="custom-prev-1"
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                padding: "0 10px",
              }}
            >
              <img
                src="/assets/icons/circle-right-arrow 6.svg"
                alt="Previous"
              />
            </div>

            <div
              className="custom-next-1"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                zIndex: 10,
                padding: "0 10px",
              }}
            >
              <img src="/assets/icons/circle-right-arrow 5.svg" alt="Next" />
            </div>

            <Swiper
              style={
                isDesktop
                  ? { padding: "0 35px" }
                  : isSmallScreen
                  ? { padding: "0 20px" }
                  : {}
              }
              modules={[Navigation]}
              navigation={{
                prevEl: ".custom-prev-1",
                nextEl: ".custom-next-1",
              }}
              spaceBetween={5}
              //  slidesPerView={3}
              breakpoints={{
                1200: {
                  slidesPerView: 3,
                },

                900: {
                  slidesPerView: 3,
                },

                600: {
                  slidesPerView: 2,
                },

                0: {
                  slidesPerView: 1,
                },
              }}
            >
              {practiceTopicList.map((item) => (
                <SwiperSlide key={item.id}>
                  <Card className="custom-card" sx={{ ...bigColumnFlexBox }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={`${baseUrl}/${item.image}`}
                        sx={cardImgStyle}
                        alt={"practice area image"}
                      />

                      <CardContent>
                        <Typography sx={{ ...blueTitle }}>
                          {theme.direction === "ltr"
                            ? item.title_en
                            : item.title_ar}
                        </Typography>

                        <Box sx={cardContentSx}>
                          <Typography
                            sx={{
                              ...blackTitle,
                              display: "-webkit-box",

                              WebkitBoxOrient: "vertical",

                              overflow: "hidden",

                              WebkitLineClamp: 3, // Limit to 3 lines

                              textOverflow: "ellipsis",
                            }}
                          >
                            {theme.direction === "ltr"
                              ? item.details_en
                              : item.details_ar}
                            {}
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Button
              fullWidth
              disableRipple
              size="large"
              type="submit"
              variant="contained"
              sx={{ ...buttonAiStyle, margin: "auto" }}
              onClick={(event) => {
                navigate(`/practice-areas`);
                dispatch(setTab("2"));
              }}
            >
              {translate("explore_more")}
            </Button>
          </Box>
          <Box sx={{ pb: "20px" }}></Box>
        </Box>
      )}

      {!isDesktop && !isSmallScreen && !isMediumScreen && (
        <Box sx={secondGrid}>
          <Box sx={boxTitleStyle}>
            <Typography sx={titleStyle}>
              {translate("key_practice_areas")}
            </Typography>

            <img alt="Line icon" src="/assets/icons/Line 1.svg" />
          </Box>

          <Box
            sx={{ pl: { lg: "25%", xs: "1%" }, pr: { lg: "25%", xs: "1%" } }}
          >
            <Typography sx={{ ...textStyle }}>
              {translate("key_practice_content")}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {practiceTopicList.map((item) => (
              <Card className="custom-card" sx={{ ...bigColumnFlexBox }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={`${baseUrl}/${item.image}`}
                    sx={cardImgStyle}
                    alt={"practice area image"}
                  />
                  <CardContent>
                    <Typography sx={{ ...blueTitle }}>
                      {theme.direction === "ltr"
                        ? item.title_en
                        : item.title_ar}
                    </Typography>

                    <Box sx={cardContentSx}>
                      <Typography
                        sx={{
                          ...blackTitle,
                          display: "-webkit-box",

                          WebkitBoxOrient: "vertical",

                          overflow: "hidden",

                          WebkitLineClamp: 3, // Limit to 3 lines

                          textOverflow: "ellipsis",
                        }}
                      >
                        {theme.direction === "ltr"
                          ? item.details_en
                          : item.details_ar}
                        {}
                      </Typography>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Button
              fullWidth
              disableRipple
              size="large"
              type="submit"
              variant="contained"
              sx={{ ...buttonAiStyle, margin: "auto" }}
              onClick={(event) => {
                navigate(`/practice-areas`);
                dispatch(setTab("2"));
              }}
            >
              Explore More
            </Button>
          </Box>
          <Box sx={{ pb: "20px" }}></Box>
        </Box>
      )}
    </>
  );
}
